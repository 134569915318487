export default {
    data() {
        return {
            fieldsWithErrors: [],
            showErrors: true,
            validationResults: {},
        };
    },
    methods: {
        validateAll() {
            const fields = Object.keys(this.formData);
            fields.forEach((field) => {
                this.validateInput(field);
            });

            return fields.filter((each) => this.formData[each].error);
        },
        isValid(key) {
            const { value, required } = this.formData[key];

            const noValue = !value || value.length < 1;

            if (required && noValue) {
                const errorString = this.formData[key].requiredError || 'This field is required';
                this.formData[key].errorString = errorString;
                return false;
            }

            if (this.formData[key].validation) {
                const validationObj = this.formData[key].validation;
                const checks = Object.keys(validationObj);

                // find validation with functions
                const checksWithCallbacks = checks.filter((check) => Boolean(validationObj[check].function));
                const fail = checksWithCallbacks.find((check) => !validationObj[check].function(key));

                if (fail) {
                    this.formData[key].errorString = validationObj[fail].errorString;
                    return false;
                }
            }

            return true;
        },
        validateInput(key) {
            const passes = this.isValid(key);

            // 'status' is for the purpose of display state only
            // only update the status for an individual field if errors should be visible
            if (this.showErrors) {
                this.formData[key].status = !passes ? 'error' : null;
            }

            if (passes && this.fieldsWithErrors.includes(key)) {
                const match = this.fieldsWithErrors.findIndex((each) => each === key);
                this.fieldsWithErrors.splice(match, 1);
            }

            this.formData[key].error = !passes;
        },
        async handleSaveAndContinue(resultsOnly) {
            this.fieldsWithErrors = this.validateAll();
            // this.fieldsWithErrors = [];
            if (this.fieldsWithErrors.length > 0) {
                this.showErrors = true;
                this.fieldsWithErrors.forEach((field) => {
                    this.formData[field].status = 'error';
                });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                const storeable = {};
                Object.keys(this.formData).forEach((field) => {
                    storeable[field] = this.formData[field].value;
                });

                // option to just get the storable fields
                if (resultsOnly) {
                    this.validationResults = storeable;
                    return;
                }

                // run any save action if validation passes
                // this should be set on the component
                if (this.saveAction) {
                    this.saveAction(storeable);
                }
            }
        },
        overlapFields(data1, data2) {
            // get all fields that exist in both of two objects
            const dataFields = Object.keys(data1).map((key) => key.toLowerCase());
            return Object.keys(data2).filter((name) => dataFields.includes(name.toLowerCase()));
        },
        async populateFields(enumFields, loadedData) {
            const data = loadedData || this.$store.state.user.data;

            const formFields = this.overlapFields(data, this.formData);
            const inputFields = formFields.filter((each) => !enumFields.includes(each));
            // handle fields that are stored as numbers but used by text inputs
            const numberFields = ['classSize'];

            // enum/select fields that need some standardization
            enumFields.forEach((each) => {
                const incomingValue = data[each];
                this.formData[each].value = incomingValue ? incomingValue.toLowerCase() : '';
            });

            // keep case of input fields, may be descriptions, titles, or longer text
            inputFields.forEach((each) => {
                if (numberFields.includes(each)) {
                    this.formData[each].value = data[each].toString();
                } else {
                    this.formData[each].value = data[each];
                }
            });

            return this.validateAll();
        },
    },
};
