import axios from 'axios';

const API_DOMAIN = process.env.VUE_APP_API_URL;

const getUploadUrl = async ({ dispatch }, payload) => {
    const accessToken = payload.token;
    const { requestId, file } = payload.data;

    let response;
    const params = new URLSearchParams([['filename', file.name]]).toString();

    try {
        response = await axios.post(`${API_DOMAIN}/requests/${requestId}/attachments?${params}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    } catch (e) {
        dispatch('updateRequestAttachment', { file: { filename: file.name }, status: 'error' });
    }

    return response;
};

const updateRequestAttachment = async ({ getters, dispatch }, payload) => {
    let { file: { filename } } = payload;
    const { file: { name, size, type }, status } = payload;

    let attachment;

    if (name) {
        filename = name;
    }

    let { requestAttachments: currentAttachments = [] } = getters;
    const idx = currentAttachments.findIndex((each) => each.filename === filename || each.name === filename);
    const isOwner = status === 'staged';

    // if attachment is already stored/pending
    if (idx >= 0) {
        if (status === 'unstaged') {
            // if unstaging, remove from list
            currentAttachments = currentAttachments.toSpliced(idx, 1);
        } else {
            // otherwise update status only
            currentAttachments[idx].status = status;
        }
    } else {
        // otherwise create a new attachment record with the correct status
        attachment = {
            filename,
            name: filename,
            size,
            type,
            status,
            is_owner: isOwner,
        };
        currentAttachments.push(attachment);
    }

    // update store
    dispatch('updatePage', { request: { ...getters.request, ...{ attachments: currentAttachments } } });
};

const uploadResource = async ({ dispatch }, payload) => {
    const { presigned, file } = payload.data;

    const formData = new FormData();
    Object.keys(presigned.fields).forEach((key) => {
        formData.append(key, presigned.fields[key]);
    });

    formData.append('file', file);

    try {
        await axios.post(presigned.url, formData, {});
        dispatch('updateRequestAttachment', { file: { filename: file.name }, status: 'success' });
    } catch (e) {
        this.$store.dispatch('updatePage', { error: { description: `Resource ${file.name} failed to upload.` } });
    }
};

const getRequestAttachments = async ({ dispatch, state }, payload) => {
    const { requestId } = payload.data;
    let response;

    try {
        response = await axios.get(`${API_DOMAIN}/requests/${requestId}/attachments`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });

        const { files: attachments } = response.data;

        attachments.sort((a, b) => {
            let result = 0;

            if (a.last_modified < b.last_modified) {
                result = -1;
            } else if (a.last_modified > b.last_modified) {
                result = 1;
            }
            // a must be equal to b
            return result;
        });

        const updatedRequest = { ...state.page.request, ...{ attachments } };
        dispatch('updatePage', { request: updatedRequest });
    } catch {
        dispatch('updatePage', { error: { description: 'We were unable to fetch your attachments' } });
    }
};

const deleteRequestAttachment = async ({ dispatch }, payload) => {
    const { requestId, filename } = payload.data;

    const params = new URLSearchParams([['filename', filename]]).toString();

    try {
        await axios.delete(`${API_DOMAIN}/requests/${requestId}/attachments?${params}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });

        return dispatch('getRequestAttachments', payload);
    } catch {
        return this.$store.dispatch('updatePage', { error: { description: `Attachment "${filename}" failed to delete.` } });
    }
};

export default {
    getUploadUrl,
    uploadResource,
    getRequestAttachments,
    deleteRequestAttachment,
    updateRequestAttachment,
};
