import axios from 'axios';

const API_DOMAIN = process.env.VUE_APP_API_URL;

const storeEducator = async (data, commit, view) => {
    if (!data) {
        // if no data, user does not exist (yet)
        // set active to false and bail
        await commit('UPDATE_APP', { active: false });
        await commit('UPDATE_USER_DATA', {
            // store that the user does not yet exist in CC
            notCreated: true,
        });
        return;
    }

    const {
        isActive,
        termsChecked,
        privacyChecked,
        educatorId: id,
        avatarUrl,
        preferredName,
        pronoun,
    } = data;

    if (view) {
        commit('UPDATE_PAGE', {
            educator: {
                isActive,
                termsChecked,
                privacyChecked,
                id,
                avatarUrl,
                preferredName,
                pronoun,
                ...{ notCreated: false },
            },
        });
        return;
    }

    if (!isActive) {
        await commit('UPDATE_APP', { active: false });
    }

    // eslint-disable-next-line consistent-return
    return commit('UPDATE_USER_DATA', {
        isActive,
        termsChecked,
        privacyChecked,
        id,
        avatarUrl,
        preferredName,
        pronoun,
        ...{ notCreated: false },
    });
};

const getEducator = async (context, payload) => {
    const { view, educatorId } = payload.data;
    const {
        state,
        commit,
        getters,
        dispatch,
    } = context;
    try {
        commit('UPDATE_LOADING', { key: 'user', status: true });
        const response = await axios.get(`${API_DOMAIN}/educators/${educatorId || state.user.data.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });

        await storeEducator(response.data, commit, view);
        if (!response?.data || response?.data?.timezone) {
            // store the DE-stored timezone if it isn't present
            const { timezone } = getters.userData;
            await dispatch('updateEducator', { token: payload.token, data: { ...response.data, ...{ timezone } } });
        }

        commit('UPDATE_LOADING', { key: 'user', status: false });
    } catch (error) {
        console.log(error);
        commit('UPDATE_LOADING', { key: 'user', status: false });
    }
};

const updateEducator = async (context, payload) => {
    const { token, data } = payload;

    const { state, commit } = context;
    const { site, ...userData } = state.user.data;

    // activate user, user cannot send data without required fields
    if (data && data.activate) {
        userData.isActive = true;
    }

    try {
        commit('UPDATE_LOADING', { key: 'user', status: true });
        const response = await axios.post(`${API_DOMAIN}/educators/${userData.id}`,
            userData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

        await storeEducator(response.data, commit);
        commit('UPDATE_ERROR', null);
        commit('UPDATE_LOADING', { key: 'user', status: false });
    } catch (error) {
        commit('UPDATE_ERROR', 'We\'re currently unable to fetch your user data. Please try again later.');
        commit('UPDATE_LOADING', { key: 'user', status: false });
    }
};

const updateEducatorAvatar = async (context, payload) => {
    const { state, commit } = context;
    try {
        const response = await axios.post(`${API_DOMAIN}/educators/${state.user.data.id}/update-avatar`,
            payload.data.fileContent,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                    'Content-Type': 'application/json',
                },
            });

        await storeEducator(response.data, commit);
    } catch (error) {
        console.log(error);
        console.log(payload);
    }
};

// const createEducator = async ({ commit }, data) => {
//     // this will ideally be handled on the backend
//     // TODO either remove or finalize when backend handles this
//     const response = await axios.post(`${API_DOMAIN}/educators/${data.educatorId}`,
//         {
//             email: data.email,
//         },
//         {
//             headers: {
//                 Authorization: `Bearer ${data.token}`,
//             },
//         });

//     // if (!response.data) {
//     //     return commit('UPDATE_USER_DATA', { firstLogin: true });
//     // }
//     console.log(commit);
//     return response.data;
//     // return commit('UPDATE_USER_DATA', response.data);
// };

export default { getEducator, updateEducator, updateEducatorAvatar };
