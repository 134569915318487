export default {
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    created() {
        window.addEventListener('resize', this.setWindowWidth);
    },
    destroyed() {
        window.removeEventListener('resize', this.setWindowWidth);
    },
    methods: {
        setWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
    },
    computed: {
        isMobile() {
            return this.windowWidth < 768;
        },
        isTablet() {
            return this.windowWidth >= 768 && this.windowWidth < 1200;
        },
        isDesktop() {
            return this.windowWidth >= 1200;
        },
    },
};
