// eslint-disable-next-line import/no-extraneous-dependencies
import 'normalize.css/normalize.css';

import axios from 'axios';
import i18next from 'i18next';
import Vue from 'vue';
import VueI18Next from '@panter/vue-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import App from './App.vue';

import router from './router';
import store from './store';

import { Auth0Plugin } from './auth';
import deAuth from './auth/deAuth';

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;

const API_DOMAIN = process.env.VUE_APP_API_URL;
const DE_CLIENT_ID = process.env.VUE_APP_DE_CLIENT_ID;
const DE_DOMAIN = process.env.VUE_APP_DE_DOMAIN;

// Install the auth0 plugin
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    onRedirect: (appState) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname,
        );
    },
});

// install the DE auth plugin
Vue.use(deAuth.deAuthPlugin, {
    apiDomain: API_DOMAIN,
    clientId: DE_CLIENT_ID,
    deDomain: DE_DOMAIN,
});

Vue.config.productionTip = false;
Vue.prototype.$nebulaSVGPath = '/img/nebula.svg';

// Setup for i18next
Vue.use(VueI18Next);

function initI18n() {
    return new Promise((resolve) => {
        const i18nextConfig = {
            lng: 'en',
            fallbackLng: {
                'zh-cn': ['zh-hans', 'en'],
                'es-es': ['es', 'en'],
                default: ['en'],
            },
            debug: false,
            returnEmptyString: false,
            nsSeparator: false,
            keySeparator: false,
            ns: [
                'common',
                'employeeprofile',
                'profile',
                'login',
                'dropdown',
                'requests',
                'requestdirectory',
                'requestdetails',
                'directrequest',
                'notifications',
                'requestcreate',
                'requestedit',
                'upload',
                'usermanagement',
                'termsconditions',
                'educatorprofile',
                'schooladmin',
                'help',
            ],
            defaultNS: 'common',
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
        };
        const localeCookie = document.cookie.split(';')
            .find((cookie) => cookie.trim().startsWith('L10N_LANG'));

        const locale = localeCookie ? localeCookie.trim().split('=')[1] : 'en';

        const langs = Object.keys(i18nextConfig.fallbackLng).filter(
            (e) => e !== 'default',
        );

        const urlParameters = new URLSearchParams(window.location.search);

        const languageCode = urlParameters.get('language') ?? window.navigator.language;

        const validLanguageCode = langs.find((e) => e.slice(0, 2).includes(languageCode.slice(0, 2))) ?? i18nextConfig.fallbackLng.default[0];
        if (validLanguageCode) {
            i18nextConfig.lng = validLanguageCode;
        }

        if (locale !== 'en') {
            i18nextConfig.lng = locale;
            const deAppOrigin = window.location.href.includes('localhost')
                ? 'https://app.local.discoveryeducation.com'
                : window.location.origin.replace('nebula', 'app');

            axios.get(`${deAppOrigin}/static/common/components/nunjucks-config/localization/${locale.toLowerCase()}.json`)
                .then((response) => {
                    i18nextConfig.fallbackLng = false;
                    i18nextConfig.resources[locale] = {
                        translation: response.data,
                    };
                    resolve(i18nextConfig);
                });
        } else {
            resolve(i18nextConfig);
        }
    });
}

initI18n()
    .then((i18nextConfig) => i18next.use(Backend).use(LanguageDetector).init(i18nextConfig))
    .then(() => {
        const i18n = new VueI18Next(i18next);

        new Vue({
            router,
            store,
            i18n,
            render: (h) => h(App),
        }).$mount('#app');
    });

export default { router };
