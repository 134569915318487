<template>
    <p class="input-limit-helper">
        <slot />
    </p>
</template>

<script>
export default {
    name: 'InputLimitHelper',
};
</script>

<style lang="stylus">
.input-limit-helper {
    text-align: right;
    font-size: $nebula-font-size-caption;
    margin-block-start: $nebula-space-half;
}
</style>
