<template>
    <transition name="nebula-modal-transition" mode="out-in">
        <NebulaModal
            v-if="showApplyModal"
            @close-modal="toggleRequestApplyModal"
            size="s"
        >
            <template v-slot:header>
                <h2>Apply to Request</h2>
            </template>
            <template v-slot:content>
                <p>
                    You are about to apply to {{ data.educatorName }}'s request.
                    Are you sure you want to apply?
                </p>
            </template>
            <template v-slot:actionsRow>
                <NebulaButton
                    v-if="!applicationLoading"
                    @click="toggleRequestApplyModal"
                    type="flat"
                    text="No, cancel"
                    :data-click-type="`Confirm Apply to Request: ${roleDisplay}`"
                    data-click-name="No, cancel"
                />
                <NebulaButton
                    :isDisabled="applicationLoading"
                    @click="applyToRequest"
                    :text="applicationLoading ? 'Processing' : 'Yes, proceed'"
                    :data-click-type="`Confirm Apply to Request: ${roleDisplay}`"
                    data-click-name="Yes, proceed"
                />
            </template>
        </NebulaModal>
        <NebulaModal
            v-else-if="showRescheduleModal"
            @close-modal="toggleRequestRescheduleModal"
            size="s"
        >
            <template v-slot:header>
                <h2>Rescheduling Request</h2>
            </template>
            <template v-slot:content>
                <p>
                    {{ rescheduleText }}
                </p>
            </template>
            <template v-slot:actionsRow>
                <NebulaButton
                    v-if="!loadingRequestSubmit"
                    @click="toggleRequestRescheduleModal"
                    type="flat"
                    text="No, cancel"
                    :data-click-type="`Confirm Reschedule: ${roleDisplay}`"
                    data-click-name="No, cancel"
                />
                <NebulaButton
                    :isDisabled="loadingRequestSubmit"
                    @click="submitRequest"
                    :text="loadingRequestSubmit ? 'Processing' : 'Yes, Save & Reschedule'"
                    :data-click-type="`Confirm Reschedule: ${roleDisplay}`"
                    data-click-name="Yes, Save & Reschedule"
                />
            </template>
        </NebulaModal>
        <NebulaModal
            v-else-if="showEditModal"
            @close-modal="toggleRequestEditModal"
            size="s"
        >
            <template v-slot:header>
                <h2>Save Edits</h2>
            </template>
            <template v-slot:content>
                <p>
                    You have made edits to your original request. Are you sure you want to save?
                </p>
            </template>
            <template v-slot:actionsRow>
                <NebulaButton
                    v-if="!loadingRequestSubmit"
                    @click="toggleRequestEditModal"
                    type="flat"
                    text="No, cancel"
                    :data-click-type="`Confirm Request Edits: ${roleDisplay}`"
                    data-click-name="No, cancel"
                />
                <NebulaButton
                    :isDisabled="loadingRequestSubmit"
                    @click="submitEditedRequest"
                    :text="loadingRequestSubmit ? 'Processing' : 'Yes, Save Edits'"
                    :data-click-type="`Confirm Request Edits: ${roleDisplay}`"
                    data-click-name="Yes, Save Edits"
                />
            </template>
        </NebulaModal>
        <NebulaModal
            v-else-if="showCancelModal"
            @close-modal="toggleRequestCancelModal"
            size="s"
        >
            <template v-slot:header>
                <h2>Cancel Request</h2>
            </template>
            <template v-slot:content>
                <p>
                    You are about to cancel this request. Are you sure you want to cancel?
                </p>
                <ProfileFormField
                    v-if="cancelScheduled"
                    name="cancellationMessage"
                    inputType="textarea"
                    :inputGroupData="formData.cancellationMessage"
                    :validation="validateInput"
                />
            </template>
            <template v-slot:actionsRow>
                <NebulaButton
                    v-if="!loadingRequestSubmit"
                    @click="toggleRequestCancelModal"
                    type="flat"
                    text="No, Don't Cancel"
                    :data-click-type="`Confirm Cancel Request: ${roleDisplay}`"
                    data-click-name="No, Don't Cancel"
                />
                <NebulaButton
                    :isDisabled="loadingRequestSubmit"
                    @click="cancelRequest"
                    :text="loadingRequestSubmit ? 'Processing' : 'Yes, Cancel'"
                    :data-click-type="`Confirm Cancel Request: ${roleDisplay}`"
                    data-click-name="Yes, Cancel"
                />
            </template>
        </NebulaModal>
        <NebulaModal
            v-else-if="showApproveModal"
            @close-modal="toggleModal('approve', 'requestApproveModal')"
            size="s"
        >
            <template v-slot:header>
                <h2>Approve an Applicant</h2>
            </template>
            <template v-slot:content>
                <p>
                    You are about to approve an applicant for this request. Are you sure you want to approve this applicant?
                </p>
                <WithinNextN :timestamp="$store.getters.requestApproveModal.startDateTime">
                    <ModalWarning>
                        <p>
                            This request is scheduled within the next 24 hours.
                            This connector may not be available at this scheduled time.
                        </p>
                        <p>
                            If you would like to change the proposed time to further in the future,
                            you may
                            <RouterLink
                                class="request-modal__link"
                                @click.native="toggleModal('approve', 'requestApproveModal')"
                                :to="`/request/${$store.getters.requestApproveModal.requestId}/edit`"
                            >
                                edit the request.
                            </RouterLink>
                        </p>
                    </ModalWarning>
                </WithinNextN>
            </template>
            <template v-slot:actionsRow>
                <NebulaButton
                    v-if="!applicationLoading"
                    @click="toggleModal('approve', 'requestApproveModal')"
                    type="flat"
                    text="Cancel"
                    :data-click-type="`Confirm Approve Applicant: ${roleDisplay}`"
                    data-click-name="Cancel"
                />
                <NebulaButton
                    :isDisabled="applicationLoading"
                    @click="approveApplicant"
                    :text="applicationLoading ? 'Processing' :'Yes, Approve'"
                    :data-click-type="`Confirm Approve Applicant: ${roleDisplay}`"
                    data-click-name="Yes, Approve"
                />
            </template>
        </NebulaModal>
        <NebulaModal
            v-else-if="showDeclineModal"
            @close-modal="toggleModal('decline', 'requestDeclineModal')"
            size="s"
        >
            <template v-slot:header>
                <h2>Decline an Applicant</h2>
            </template>
            <template v-slot:content>
                <p>
                    You are about to decline an applicant.
                    Once an applicant is declined, they will no longer be in your applicant list.
                    Are you sure you want to continue?
                </p>
                <ProfileFormField
                    name="declineReason"
                    :inputGroupData="formData.declineReason"
                    :validation="validateInput"
                    :selectOptions="applicantDeclineReasons"
                />
            </template>
            <template v-slot:actionsRow>
                <NebulaButton
                    v-if="!applicationLoading"
                    @click="toggleModal('decline', 'requestDeclineModal')"
                    type="flat"
                    text="No, Keep Applicant"
                    :data-click-type="`Confirm Decline Applicant: ${roleDisplay}`"
                    data-click-name="No, Keep Applicant"
                />
                <NebulaButton
                    :isDisabled="applicationLoading"
                    @click="declineApplicant"
                    :text="applicationLoading ? 'Processing' : 'Yes, Decline'"
                    :data-click-type="`Confirm Decline Applicant: ${roleDisplay}`"
                    data-click-name="Yes, Decline"
                />
            </template>
        </NebulaModal>
        <NebulaModal
            v-else-if="showDeclineDirectModal"
            @close-modal="toggleModal('decline-direct', 'requestDeclineDirectModal')"
            size="s"
        >
            <template v-slot:header>
                <h2>Decline Request</h2>
            </template>
            <template v-slot:content>
                <p>
                    Are you sure you want to decline this request?
                </p>
            </template>
            <template v-slot:actionsRow>
                <NebulaButton
                    v-if="!loadingDecline"
                    @click="toggleModal('decline-direct', 'requestDeclineDirectModal')"
                    type="flat"
                    text="No, Cancel"
                    :data-click-type="`Confirm Decline Direct Request: ${roleDisplay}`"
                    data-click-name="No, Cancel"
                />
                <NebulaButton
                    class="request-modal__danger-button"
                    :isDisabled="loadingDecline"
                    @click="declineDirectRequest"
                    :text="loadingDecline ? 'Processing' : 'Yes, Decline'"
                    :data-click-type="`Confirm Decline Direct Request: ${roleDisplay}`"
                    data-click-name="Yes, Decline"
                />
            </template>
        </NebulaModal>
    </transition>
</template>

<script>
import { NebulaModal, NebulaButton } from '@discoveryedu/nebula-components';
import createPayload from '@/mixins/data/createPayload';
import ModalWarning from '@/components/modals/shared/ModalWarning.vue';
import WithinNextN from '@/components/shared/utilities/WithinNextN.vue';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import {
    scheduled,
    pendingMatches,
    roles,
    ROLE_DISPLAY_MAP,
} from '@/constants';
import validation from '@/mixins/form/validation';
import {
    applicantDeclineReasons,
} from '@/data/formData';

export default {
    name: 'UserManagementModals',
    components: {
        NebulaModal,
        NebulaButton,
        ModalWarning,
        WithinNextN,
        ProfileFormField,
    },
    mixins: [createPayload, validation],
    computed: {
        applicantDeclineReasons,
        showApplyModal() {
            return this.$store.getters.requestApplyModal.show;
        },
        showRescheduleModal() {
            return this.$store.getters.requestRescheduleModal.show;
        },
        showEditModal() {
            return this.$store.getters.requestEditModal.show;
        },
        showCancelModal() {
            return this.$store.getters.requestCancelModal.show;
        },
        showApproveModal() {
            return this.$store.getters.requestApproveModal.show;
        },
        showDeclineModal() {
            return this.$store.getters.requestDeclineModal.show;
        },
        showDeclineDirectModal() {
            return this.$store.getters.requestDeclineDirectModal.show;
        },
        data() {
            return this.$store.getters.requestApplyModal;
        },
        cancelScheduled() {
            return this.$store.getters.requestCancelModal.requestStatus === scheduled;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        roleDisplay() {
            return ROLE_DISPLAY_MAP[this.appMode.trim().toLowerCase()];
        },
        applicationLoading() {
            return this.$store.state.status.request_apply.loading;
        },
        loadingRequestSubmit() {
            return this.$store.state.status.request_submit.loading;
        },
        loadingDecline() {
            return this.$store.state.status.request_direct_decline.loading;
        },
    },
    data() {
        return {
            rescheduleText: 'Are you sure you want to reschedule this request?',
            category: 'request',
            formData: {
                cancellationMessage: {
                    value: '',
                    display: `Send a message to the request ${this.appMode === roles.EMPLOYEE_ROLE ? 'owner' : 'participant'}`,
                    required: false,
                    error: null,
                    status: '',
                    maxLength: 250,
                    placeholder: 'Write a message',
                },
                declineReason: {
                    value: '',
                    display: 'Select a reason for declining the applicant',
                    required: true,
                    error: null,
                    status: '',
                    helperText: 'This feedback will be sent to the applicant',
                },
            },
        };
    },
    methods: {
        toggleRequestApplyModal() {
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'apply',
                show: !this.$store.getters.requestApplyModal.show,
            });
        },
        toggleRequestRescheduleModal() {
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'reschedule',
                show: !this.$store.getters.requestRescheduleModal.show,
            });
        },
        toggleRequestEditModal() {
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'edit',
                show: !this.$store.getters.requestEditModal.show,
            });
        },
        toggleRequestCancelModal() {
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'cancel',
                show: !this.$store.getters.requestCancelModal.show,
            });
        },
        toggleRequestDeclineDirectModal() {
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'decline-direct',
                show: !this.$store.getters.requestDeclineDirectModal.show,
            });
        },
        toggleModal(type, getter) {
            this.$store.dispatch('toggleModal', {
                category: this.category,
                type,
                show: !this.$store.getters[getter].show,
            });
        },
        async applyToRequest() {
            this.$store.dispatch('updateLoading', { key: 'request_apply', status: true });
            const {
                avatarUrl: employeeAvatarUrl,
                firstName: employeeFirstName,
                lastName: employeeLastName,
                employeeId,
                preferredName: employeePreferredName,
            } = this.$store.state.user.data;

            const { requestId } = this.data;

            const myData = {
                employeeAvatarUrl,
                employeeFirstName,
                employeeLastName,
                employeeId,
                employeePreferredName,
                operation: 'apply',
                requestId,
            };
            const payload = await this.createPayload(myData);

            this.$store.dispatch('updateRequest', payload);

            const reFetchPayload = await this.createPayload({ requestId });
            await this.$store.dispatch('getRequests', reFetchPayload);

            this.$store.dispatch('updateLoading', { key: 'request_apply', status: false });
            this.$store.dispatch('updateResults', { key: 'request_apply', status: 'success' });

            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'apply',
                show: false,
            });
        },
        routeToRequestDetails(request) {
            const { requestId } = request;
            this.$router.push(`/request/${requestId}`);
        },
        async submitRequest() {
            this.$store.dispatch('updateLoading', { key: 'request_submit', status: true });

            const { requestToSend } = this.$store.getters.requestRescheduleModal;
            const payload = await this.createPayload(requestToSend);
            await this.$store.dispatch('updateRequest', payload);
            this.toggleRequestRescheduleModal();

            this.$store.dispatch('updateLoading', { key: 'request_submit', status: false });

            this.routeToRequestDetails(requestToSend);
        },
        async submitEditedRequest() {
            this.$store.dispatch('updateLoading', { key: 'request_submit', status: true });

            const { requestToSend } = this.$store.getters.requestEditModal;
            const payload = await this.createPayload(requestToSend);
            await this.$store.dispatch('updateRequest', payload);
            this.$store.dispatch('updateLoading', { key: 'request_submit', status: false });

            this.toggleRequestEditModal();
            this.routeToRequestDetails(requestToSend);
        },
        async cancelRequest() {
            this.$store.dispatch('updateLoading', { key: 'request_submit', status: true });

            const { requestId } = this.$store.getters.requestCancelModal;
            const payload = await this.createPayload({ requestId, cancelReason: this.formData.cancellationMessage.value });
            await this.$store.dispatch('cancelRequest', payload);
            this.$store.dispatch('updateLoading', { key: 'request_submit', status: false });

            this.toggleRequestCancelModal();
            this.$router.push('/');
        },
        async declineDirectRequest() {
            this.$store.dispatch('updateLoading', { key: 'request_direct_decline', status: true });

            const { requestId } = this.$store.getters.requestDeclineDirectModal;
            const payload = await this.createPayload({ requestId });
            await this.$store.dispatch('declineDirectRequest', payload);
            this.$store.dispatch('updateLoading', { key: 'request_direct_decline', status: false });

            this.toggleRequestDeclineDirectModal();
            this.$router.push('/');
        },
        async approveApplicant() {
            this.$store.dispatch('updateLoading', { key: 'request_apply', status: true });

            const { requestId, employeeId } = this.$store.getters.requestApproveModal;

            const payload = await this.createPayload({ employeeId, requestId });
            await this.$store.dispatch('acceptApplicant', payload);

            const statusPayload = await this.createPayload({ status: scheduled, requestId });
            await this.$store.dispatch('updateRequest', statusPayload);

            if (this.$store.getters.request.status === scheduled) {
                await this.$store.dispatch('updateResults', { key: 'application_accept', status: 'success' });
            }
            // enable messaging after a request transitions to scheduled
            // const messagePayload = await this.createPayload({ requestId });
            // this.$store.dispatch('createMessageStore', messagePayload);

            this.$store.dispatch('updateLoading', { key: 'request_apply', status: false });

            this.toggleModal('approve', 'requestApproveModal');
        },
        async declineApplicant() {
            this.$store.dispatch('updateLoading', { key: 'request_apply', status: true });

            const { requestId, employeeId } = this.$store.getters.requestDeclineModal;
            const { declineReason: { value: declineReason } } = this.formData;

            const payload = await this.createPayload({ employeeId, requestId, decline_reason: declineReason });

            await this.$store.dispatch('declineApplicant', payload);

            const applicantsPayload = await this.createPayload({ requestId });
            await this.$store.dispatch('getApplicants', applicantsPayload);

            const hasActiveApplicant = !!this.$store.state.page.applicants.find((applicant) => !applicant.declined);

            // if there are no active, not-declined applicants, update the status back to pending matches
            if (!hasActiveApplicant) {
                const statusPayload = await this.createPayload({ status: pendingMatches, requestId });
                await this.$store.dispatch('updateRequest', statusPayload);
            }

            this.$store.dispatch('updateLoading', { key: 'request_apply', status: false });

            this.toggleModal('decline', 'requestDeclineModal');
        },
    },
    watch: {
        showRescheduleModal(show) {
            if (show) {
                const { hasConnector } = this.$store.getters.requestRescheduleModal;
                if (hasConnector) {
                    this.rescheduleText = `
                        Are you sure you want to reschedule this request? If so, the employee assigned to this request will
                        need to re-confirm their availability prior to re-accepting this updated
                        request.`;
                }
            }
        },
    },
};
</script>

<style lang="stylus">
.request-modal {
    &__danger-button {
        --nebula-button-background: var(----nebula-color-feedback-error-600);
        --nebula-button-background-hover: var(----nebula-color-feedback-error-700);
        --nebula-button-background-active: var(----nebula-color-feedback-error-800);
    }
    &__link {
        link();
    }
}
</style>
