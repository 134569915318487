import notifications from '@/constants/notifications';
import { roles } from '@/constants';
import {
    dateString,
} from '@/data/placeholder/date';
import formatTime from '@/utils/formatTime';

// eslint-disable-next-line arrow-body-style
const declineReasonString = (data) => {
    const { decline_reason: declineReason } = data;
    if (!declineReason) {
        return '';
    }

    switch (declineReason) {
    case 'not-a-fit':
        return ' due to your Area of Expertise not being a fit for my request';
    case 'prefer-local':
        return ' due to a preference for a Professional local to their area';
    case 'different-applicant':
        return ' due to a different applicant being more aligned to the request';
    case 'will-follow':
        return ', be on the lookout for a direct request for a new connect opportunity';
    default:
        return '';
    }
};

const getNotificationDescription = (type, data, appMode, partnerString) => {
    let description = data.request.title;
    let partnerName;

    if (!data) {
        return '';
    }

    const partnerData = data[partnerString];
    if (partnerData) {
        let { first_name: firstName, last_name: lastName } = partnerData;
        if (!lastName) {
            lastName = partnerData.lastName;
        }
        if (!firstName) {
            firstName = partnerData.preferred_name || partnerData.preferredName || partnerData.firstName;
        }

        if (!firstName) {
            partnerName = partnerString === 'employee' ? 'a connector' : `an ${partnerString}`;
        } else {
            partnerName = `${firstName} ${lastName}`;
        }
    }

    if (notifications.requestNotifications.includes(type)) {
        let requestTitle = data.request.title;

        if (!requestTitle) {
            requestTitle = 'a request';
        }

        const generalNotifications = [
            'upcoming_connect_1_day',
            'upcoming_connect_3_days',
        ];
        const dateNotifications = [
            'upcoming_connect_1_day',
            'upcoming_connect_3_days',
            'new_scheduled_request',
            'connect_cancel',
            'reschedule_request',
            'rescheduled_request_declined',
            'rescheduled_request_confirmed',
            'application_sent',
            'application_update',
            'application_declined',
            'employee_canceled',
            'new_direct_request',
        ];
        const timeNotifications = [
            'upcoming_connect_1_day',
            'upcoming_connect_3_days',
            'reschedule_request',
            'rescheduled_request_declined',
            'rescheduled_request_confirmed',
            'application_declined',
        ];

        let requestDate;
        let requestTime;

        if (dateNotifications.includes(type)) {
            const startTime = data.request.startDateTime || data.request.start;
            requestDate = startTime ? dateString(startTime * 1000) : 'a day';
        }

        if (timeNotifications.includes(type)) {
            const startTime = data.request.startDateTime || data.request.start;
            requestTime = startTime ? formatTime(startTime * 1000) : 'a time';
        }
        if (generalNotifications.includes(type)) {
            switch (type) {
            case 'upcoming_connect_1_day':
                description = `You have an upcoming event scheduled on ${requestDate} at ${requestTime}!`;
                break;
            case 'upcoming_connect_3_day':
                description = `You have an upcoming event scheduled on ${requestDate} at ${requestTime}!`;
                break;
            default:
                description = data.request.title;
            }
        } else if (appMode === roles.EMPLOYEE_ROLE) {
            switch (type) {
            case 'new_direct_request':
                description = `You have a new direct request from ${partnerName}!`;
                break;
            case 'application_sent':
                description = `Your application for ${requestTitle} on ${requestDate} has been sent to ${partnerName} for approval.`;
                break;
            case 'new_direct_request_reminder':
                description = `${partnerName} is waiting for your confirmation on their direct request.`;
                break;
            case 'new_scheduled_request':
                description = `Your application for ${requestTitle} on ${requestDate} has been approved by ${partnerName}.`;
                break;
            case 'request_cancel':
                description = 'An open request you applied to was canceled by the educator.';
                break;
            case 'connect_cancel':
                description = `${partnerName} has canceled ${requestTitle} on ${requestDate}`;
                break;
            case 'reschedule_request':
                description = `${partnerName} has rescheduled ${requestTitle} for ${requestDate} at ${requestTime}. Please view details to confirm.`;
                break;
            case 'application_update':
                description = `${partnerName} has moved forward with another applicant for ${requestTitle} on ${requestDate}.`;
                break;
            case 'application_declined':
                description = `Your application for ${requestTitle} was declined by ${partnerName}${declineReasonString(data)}`;
                break;
            default:
                description = data.request.title;
            }
        } else if (appMode === roles.EDUCATOR_ROLE) {
            switch (type) {
            case 'confirm_employee':
                description = `${partnerName} is waiting for your confirmation on their request application.`;
                break;
            case 'new_direct_request':
                description = `
                    Your direct request to ${partnerName} on ${requestDate} for ${requestTitle} has been sent for ${partnerName}'s approval.
                `;
                break;
            case 'new_scheduled_request':
                description = `${partnerName} has accepted your direct request for ${requestTitle} on ${requestDate}.`;
                break;
            case 'rescheduled_request_declined':
                description = `
                    ${partnerName} has declined your rescheduled ${requestTitle} 
                    on ${requestDate} at ${requestTime}. This request will return to open status.
                `;
                break;
            case 'rescheduled_request_confirmed':
                description = `
                    ${partnerName} has accepted your rescheduled ${requestTitle} on ${requestDate} at ${requestTime}.
                `;
                break;
            case 'application_declined':
                description = `
                    ${partnerName} has declined your direct request ${requestTitle} on ${requestDate} at ${requestTime}.
                    This request will return to open status.
                `;
                break;
            case 'employee_canceled':
                description = `${partnerName} has canceled ${requestTitle} on ${requestDate}`;
                break;
            default:
                description = data.request.title;
            }
        }
    }

    description = description.trim();

    return description.charAt(0).toUpperCase()
    + description.slice(1);
};

export default getNotificationDescription;
