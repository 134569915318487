<template>
    <transition name="nebula-modal-transition" mode="out-in">
        <NebulaModal
            v-if="showRemoveModal"
            @close-modal="toggleRemoveModal"
            size="s"
        >
            <template v-slot:header>
                <h2>Remove User</h2>
            </template>
            <template v-slot:content>
                <p>
                    You are about to remove {{ $store.getters.removeUserModal.email }} from Career Connect.
                    Are you sure you want to proceed?
                </p>
            </template>
            <template v-slot:actionsRow>
                <NebulaButton
                    @click="toggleRemoveModal"
                    type="flat"
                    text="No, cancel"
                />
                <NebulaButton
                    @click="removeEmployee"
                    text="Yes, proceed"
                />
            </template>
        </NebulaModal>
    </transition>
</template>

<script>
import { NebulaModal, NebulaButton } from '@discoveryedu/nebula-components';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'UserManagementModals',
    components: {
        NebulaModal,
        NebulaButton,
    },
    mixins: [createPayload],
    computed: {
        showRemoveModal() {
            return this.$store.getters.removeUserModal.show;
        },
    },
    methods: {
        toggleRemoveModal() {
            this.$store.dispatch('toggleModal', {
                category: 'user_management',
                type: 'remove',
                show: !this.$store.getters.removeUserModal.show,
            });
        },
        async removeEmployee() {
            const employeeData = this.$store.getters.removeUserModal;
            const payload = await this.createPayload({ employeeId: employeeData.employeeId });
            await this.$store.dispatch('removeEmployee', payload);
            this.$store.dispatch('toggleModal', {
                category: 'user_management',
                type: 'remove',
                show: !this.$store.getters.removeUserModal.show,
            });

            const removalPayload = await this.createPayload();
            await this.$store.dispatch('getOrganizationEmployees', removalPayload);
        },
    },
};
</script>
