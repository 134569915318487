import Vue from 'vue';
import VueRouter from 'vue-router';
import { routeGuide } from '../auth/authGuard';

import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(routeGuide);

export default router;
