<template>
    <GlobalNavButton
        :href="data.path"
        class="careers-global-nav-item"
        :name="data.display"
    >
        <div class="careers-global-nav-item__summary">
            <div class="careers-global-nav-item__icon-wrapper">
                <NebulaIcon
                    :symbolId="data.icon"
                    size="s"
                />
            </div>
            <h3 class="careers-global-nav-item__link">{{ data.display }}</h3>
        </div>
    </GlobalNavButton>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import GlobalNavButton from '@/components/shared/GlobalNavButton.vue';

export default {
    name: 'GlobalNavItem',
    components: {
        NebulaIcon,
        GlobalNavButton,
    },
    props: {
        data: {
            type: Object,
        },
    },
};
</script>

<style lang="stylus">
    :root {
        --careers-global-nav-item-icon-border-radius: $nebula-border-radius-badge-default;
        --careers-global-nav-item-icon-padding: $nebula-space-half;
    }

    icon-wrapper() {
        border-radius: var(--careers-global-nav-item-icon-border-radius);
        display: inline-block;
        line-height: 0;
        padding: var(--careers-global-nav-item-icon-padding);
    }

    product-icon() {
        svg {
            fill: $nebula-color-white;
        }
    }

    .careers-global-nav-item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $nebula-space-1x;

        &:last-child {
            margin-bottom: 0;
        }

        &__text {
            nebula-text-button-2();
            color: $nebula-color-platform-interactive-1000;
        }

        &__summary {
            align-items: center;
            display: flex;
            gap: $nebula-space-1x;

            &>h3 {
                margin: 0;
            }
        }

        &__link {
            nebula-text-button-2();
            color: $nebula-color-platform-interactive-1000;
        }

        &__icon-wrapper {
            icon-wrapper();
            svg {
                fill: $nebula-color-platform-interactive-1000;
            }
        }

        .icon-wrapper {
            icon-wrapper();
            background-color: $nebula-color-black;

            svg {
                fill: $nebula-color-white;
            }
        }

        [dir="rtl"] & {
            &__caret {
                transform: scaleX(-1);
            }
        }
    }
</style>
