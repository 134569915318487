import roles from './roles';

// notifications that should route to request details
const requestNotifications = [
    'application_declined',
    'application_sent',
    'application_update',
    'confirm_employee',
    'connect_cancel',
    'direct_request_sent',
    'employee_canceled',
    'new_direct_request',
    'new_direct_request_reminder',
    'new_scheduled_request',
    'open_request_cancelled',
    'request_cancel',
    'reschedule_request',
    'rescheduled_request_declined',
    'rescheduled_request_confirmed',
    'upcoming_connect_1_day',
    'upcoming_connect_3_days',
    'upcoming_connect_1_hour',
    'upcoming_connect_3_hours',
];

const messageNotifications = [
    'new_message',
];

const notificationDisplayMap = {
    application_declined: 'Application Declined',
    application_sent: 'Application Sent',
    application_update: 'Application Update',
    confirm_employee: 'Action Required - Application Needs Approval',
    connect_cancel: 'Connect Canceled',
    direct_request_sent: 'Direct Request Sent',
    employee_canceled: 'Connect canceled',
    new_message: 'New Message',
    new_direct_request: 'New Direct Request',
    new_direct_request_reminder: 'Action Required',
    new_scheduled_request: 'New Scheduled Request',
    request_cancel: 'Open Request Canceled',
    reschedule_request: 'Rescheduled Request',
    rescheduled_request_declined: 'Rescheduled Request - Declined',
    rescheduled_request_confirmed: 'Rescheduled Request - Confirmed',
    upcoming_connect_1_day: 'Reminder: Upcoming Connect',
    upcoming_connect_3_days: 'Reminder: Upcoming Connect',
    upcoming_connect_1_hour: 'Reminder: Upcoming Connect (1 hour)',
    upcoming_connect_3_hours: 'Reminder: Upcoming Connect (3 hours)',
};

const hasCancelMessages = ['employee_canceled', 'connect_cancel'];

const notificationSettingsType = {
    new_message: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'New Message Received',
            [roles.EDUCATOR_ROLE]: 'New Message Received',
        },
    },
    confirm_employee: {
        display: {
            [roles.EDUCATOR_ROLE]: 'Status Change to Action Required',
        },
    },
    upcoming_connect: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'Reminder: Upcoming Connect',
            [roles.EDUCATOR_ROLE]: 'Reminder: Upcoming Connect',
        },
        notification_types: ['upcoming_connect_1_day', 'upcoming_connect_3_days', 'upcoming_connect_1_hour', 'upcoming_connect_3_hours'],
    },
    connect_cancel: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'Connect Canceled',
            [roles.EDUCATOR_ROLE]: 'Connect Canceled',
        },
        notification_types: ['connect_cancel', 'employee_canceled'],
    },
    request_cancel: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'Open Request Canceled',
        },
    },
    new_direct_request: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'New Direct Request',
            [roles.EDUCATOR_ROLE]: 'Direct Request Sent',
        },
        notification_types: ['new_direct_request', 'new_direct_request_reminder'],
    },
    application_sent: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'Application Sent',
        },
    },
    application_declined: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'Application Declined',
            [roles.EDUCATOR_ROLE]: 'Direct Request Declined',
        },
    },
    application_update: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'Application Update',
        },
    },
    new_scheduled_request: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'New Connect Scheduled',
            [roles.EDUCATOR_ROLE]: 'New Connect Scheduled',
        },
    },
    reschedule_request: {
        display: {
            [roles.EMPLOYEE_ROLE]: 'Rescheduled Connect',
        },
    },
    rescheduled_request_declined: {
        display: {
            [roles.EDUCATOR_ROLE]: 'Rescheduled Connect Declined',
        },
    },
};

export default {
    notificationDisplayMap,
    messageNotifications,
    requestNotifications,
    hasCancelMessages,
    notificationSettingsType,
};
