export default async (authService) => {
    let token;
    try {
        let idClaims = await authService.getIdTokenClaims();
        const { exp } = idClaims;

        const now = new Date();
        const nowSeconds = Math.floor(now.getTime() / 1000);

        // if id token has expired or is about to expire, refresh
        if (nowSeconds + 60 > exp) {
            authService.getTokenSilently({
                cacheMode: 'off',
            });

            idClaims = await authService.getIdTokenClaims();
        }

        const { __raw: rawToken } = idClaims;
        token = rawToken;
    } catch (e) {
        console.log(e);
    }

    return token;
};
