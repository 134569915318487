<template>
    <NebulaPageWrapper padded contained>
        <NebulaGridRow gutter class="layout-loader__row">
            <NebulaGridBlock :columns="4">
                <GrayBoxLoader card />
            </NebulaGridBlock>
            <NebulaGridBlock :columns="8">
                <GrayBoxLoader card />
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow gutter>
            <NebulaGridBlock :columns="12">
                <GrayBoxLoader card />
            </NebulaGridBlock>
        </NebulaGridRow>
    </NebulaPageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';

export default {
    name: 'GrayBoxAppLayout',
    components: {
        GrayBoxLoader,
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
    },
};
</script>

<style lang="stylus">
.layout-loader {
    &__row {
        margin-block-end: $nebula-space-2x;
    }
}
</style>
