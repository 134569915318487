<template>
    <div v-if="show">
        <slot />
    </div>
</template>

<script>
import { timestampNow } from '@/data/placeholder/date';

export default {
    name: 'WithinNextN',
    props: {
        days: {
            type: Number,
            default: 1,
        },
        hours: Number,
        timestamp: {
            type: Number,
            required: true,
        },
    },
    computed: {
        show() {
            const now = timestampNow();
            const timeDiff = this.timestamp - now;

            let compare;
            // if hours passed in, use that
            // otherwise default to days
            if (this.hours) {
                compare = this.hours * 60 * 60;
            } else {
                compare = this.days * 24 * 60 * 60;
            }

            return timeDiff > 0 && timeDiff < compare;
        },
    },
};
</script>

<style lang="stylus">
</style>
