// converts the organizations Object into an array and
// returns it separate from the rest of the employee object
function parseEmployee(data, stateData) {
    let orgs = [];
    let dataToParse;

    // support for nested within key
    const currentId = Object.keys(data)[0];
    const isNested = Boolean(data[currentId].employeeId);

    // check for nested data
    // remove this once the API is more stable
    if (isNested) {
        dataToParse = data[currentId];
    } else {
        // otherwise use data as flat object
        dataToParse = data;
    }

    const { organizations, ...rest } = dataToParse;

    // if this is a user's own data, combine it with the roles from the userData
    const { organizations: currentOrganizations } = stateData;

    if (currentOrganizations) {
        currentOrganizations.forEach((organization) => {
            const match = organizations.find((org) => org.organizationId === organization.organization_id);
            const { name, avatarUrl } = match;
            orgs.push({
                ...organization,
                ...{
                    name,
                    avatarUrl,
                },
            });
        });
    } else {
        orgs = dataToParse.organizations.map((organization) => {
            let organizationId;
            const { avatarUrl, name } = organization;

            // enforce consistent key format
            if (organization.organization_id) {
                organizationId = organization.organization_id;
            } else {
                organizationId = organization.organizationId;
            }

            return {
                organizationId,
                avatarUrl,
                name,
            };
        });
    }

    return {
        data: {
            ...rest,
        },
        organizations: orgs,
    };
}

export default parseEmployee;
