const errorByCode = {
    401: {
        title: '401 Unauthorized',
        message: 'You are missing permissions, access is allowed only for registered users.',
    },
    404: {
        title: '404 Page Not Found',
        message: 'Sorry, we couldn’t find the page you are looking for.',
    },
    500: {
        title: '500 Server Error',
        message: 'Oops, something went wrong. Please try again later.',
    },
    501: {
        title: '501 Not Implemented',
        message: 'Oops, your request resulted in an error',
    },
    502: {
        title: '502 Bad Gateway',
        message: 'Oops! We are taking too long to respond.',
    },
    503: {
        title: '503 Service Unavailable',
        message: 'Oops, the server is temporarily busy, try again in a few minutes.',
    },
    504: {
        title: '504 Gateway Timeout',
        message: 'Sorry about that. Please try refreshing or contact us.',
    },
};

const supportedCodes = Object.keys(errorByCode);

const defaultError = {
    title: 'Oops, something went wrong',
    message: 'Please try again later.',
};

const populatePageError = (status, commit) => {
    let errorContent = defaultError;

    if (status && supportedCodes.includes(status.toString())) {
        errorContent = errorByCode[status.toString()];
    }

    return commit('UPDATE_ERROR', {
        ...errorContent,
    });
};

export default {
    errorByCode,
    supportedCodes,
    defaultError,
    populatePageError,
};
