<template>
    <NebulaFieldGroup
        :inputId="inputId"
        :labelText="$t(inputGroupData.display)"
        :isRequired="inputGroupData.required"
        :helperText="helperText"
        :class="className"
    >
        <template v-if="isSelect" v-slot:formElement>
            <NebulaSelect
                class="careers-form-select"
                :status="inputGroupData.status"
            >
                <template v-slot:select>
                    <select
                        v-bind="disabled"
                        class="nebula-select__element"
                        :id="inputId"
                        :name="name"
                        :key="inputGroupData.value"
                        :aria-describedby="helperId"
                        v-model="inputGroupData.value"
                        @change="selectChange()"
                    >
                        <NebulaSelectOption
                            text="Select"
                            value=""
                        />
                        <slot name="customOptions">
                            <NebulaSelectOption
                                v-for="(option, index) in selectOptions"
                                v-bind="option"
                                :key="index"
                            />
                        </slot>
                    </select>
                </template>
            </NebulaSelect>
            <NebulaFormHelper
                v-if="inputGroupData.status == 'error'"
                :text="inputGroupData.errorString || $t('Required')"
                icon="warning--filled"
                :helperId="helperId"
                :status="inputGroupData.status"
                role="alert"
            />
        </template>
        <template v-else-if="inputType == 'multi-select'" v-slot:formElement>
            <NebulaVueSelect
                v-if="!loading"
                v-bind="disabled"
                class="profile-form-field__vue-select"
                :options="selectOptions"
                :multiple="true"
                :value="multiSelectValue"
                placeholder="Select"
                :status="inputGroupData.status"
                size="m"
                @change-selected="multiSelectTracker"
            />
        </template>
        <template v-else-if="inputType == 'check-box'" v-slot:formElement>
            <div class="nebula-checkbox-group tnc__group">
                <NebulaCheckbox :status="inputGroupData.status">
                    <template v-slot:checkbox>
                        <input
                            class="nebula-checkbox"
                            :id="name"
                            :name="name"
                            type="checkbox"
                            v-model="inputGroupData.value"
>
                    </template>
                </NebulaCheckbox>
                <slot name="checkbox-label" />
            </div>
        </template>
        <template v-else-if="inputType == 'datepicker'" v-slot:formElement>
            <NebulaDatepicker
                v-bind="disabled"
                :wrapperClass="datepickerClass()"
                :id="inputId"
                v-model="inputGroupData.value"
                @input="validate()"
            />
            <NebulaIcon symbolId="calendar-add" class="profile-form-field__calendar-icon" />
            <NebulaFormHelper
                v-if="inputGroupData.status == 'error'"
                :text="inputGroupData.errorString || $t('Required')"
                icon="warning--filled"
                :helperId="helperId"
                :status="inputGroupData.status"
                role="alert"
            />
        </template>
        <template v-slot:formElement v-else>
            <component
                v-bind="disabled"
                :is="inputComponent"
                :class="inputType === 'textarea' && 'profile-form-field__textarea'"
                :aria-describedby="helperId"
                :id="inputId"
                :placeholder="inputGroupData.placeholder || $t('Text')"
                :status="inputGroupData.status"
                :maxlength="inputGroupData.maxLength"
                v-model="inputGroupData.value"
                @input="validate()"
            />
            <NebulaFormHelper
                v-if="inputGroupData.status == 'error'"
                :text="inputGroupData.errorString || $t('Required')"
                icon="warning--filled"
                :helperId="helperId"
                :status="inputGroupData.status"
                role="alert"
            />
            <div v-if="inputGroupData.maxLength" class="profile__helper-text-count">
                <InputLimitHelper>
                    {{ $t('characters-remaining',
                    {
                        descriptionCharactersLeft: charsLeft,
                        maxDescriptionLength: inputGroupData.maxLength,
                        ns: 'employeeprofile',
                    })
                    }}
                </InputLimitHelper>
            </div>
        </template>
    </NebulaFieldGroup>
</template>

<script>
import {
    NebulaCheckbox,
    NebulaDatepicker,
    NebulaFieldGroup,
    NebulaFormHelper,
    NebulaIcon,
    NebulaInput,
    NebulaSelect,
    NebulaSelectOption,
    NebulaTextarea,
    NebulaVueSelect,
} from '@discoveryedu/nebula-components';
import InputLimitHelper from '@/components/shared/forms/InputLimitHelper.vue';

export default {
    name: 'ProfileFormField',
    components: {
        NebulaDatepicker,
        NebulaFieldGroup,
        NebulaFormHelper,
        NebulaTextarea,
        NebulaIcon,
        NebulaInput,
        NebulaSelect,
        NebulaSelectOption,
        InputLimitHelper,
        NebulaVueSelect,
        NebulaCheckbox,
    },
    props: {
        inputGroupData: {
            type: Object,
        },
        inputType: {
            type: String,
            default: 'text',
            validator(val) {
                const allowed = ['text', 'select', 'textarea', 'multi-select', 'check-box', 'datepicker'];
                return allowed.includes(val);
            },
        },
        flex: {
            type: Number,
            default: 1,
            validator(val) {
                const allowed = [1, 2, 3];
                return allowed.includes(val);
            },
        },
        name: {
            type: String,
            required: true,
        },
        validation: {
            type: Function,
        },
        selectOptions: {
            type: Array,
        },
        helperText: {
            type: String,
        },
        loading: {
            type: Boolean,
        },
        value: {
            type: Boolean,
        },
        disabled: Object,
    },
    computed: {
        className() {
            return [
                'profile-form-field',
                `profile-form-field--flex-${this.flex}`,
            ];
        },
        inputId() {
            return this.name;
        },
        helperId() {
            return `${this.name}-helper`;
        },
        inputComponent() {
            if (this.inputType === 'textarea') {
                return NebulaTextarea;
            }

            return NebulaInput;
        },
        multiSelectValue() {
            const matches = this.inputGroupData.value.map((each) => this.selectOptions.find((option) => option.value === each));
            return matches;
        },
        isSelect() {
            // return true;
            if (this.inputType === 'multi-select') {
                return false;
            }
            const hasOptions = !!this.selectOptions;
            return hasOptions || this.inputType === 'select';
        },
    },
    data() {
        return {
            charsLeft: this.inputGroupData.maxLength,
        };
    },
    methods: {
        datepickerClass() {
            const classes = ['profile-form-field__datepicker'];

            if (this.inputGroupData.status === 'error') {
                classes.push('profile-form-field__datepicker--error');
            }

            return classes.join(' ');
        },
        multiSelectTracker(options) {
            const values = options.map((each) => each.value || each);
            this.$emit('update-multi-select', { name: this.name, values });
            this.validate();
        },
        enforceCharLimit() {
            const { value, maxLength } = this.inputGroupData;

            // if value goes over the char limit, don't update
            if (value.length > maxLength) {
                this.inputGroupData.value = value.slice(0, maxLength);
            } else {
                // otherwise calculate the characters left
                this.charsLeft = maxLength - value.length;
            }
        },
        selectChange() {
            this.validate();
            this.$emit('update-select');
        },
        validate() {
            // run any passed-in validation func
            if (this.validation) {
                this.validation(this.name);
            }
            // if there's a max char, enforce it
            if (this.inputGroupData.maxLength) {
                this.enforceCharLimit();
            }
        },
    },
    async mounted() {
        await this.$nextTick;
        if (this.inputGroupData.maxLength) {
            const { maxLength, value } = this.inputGroupData;
            const blank = value === null || value === undefined || value.length < 1;
            this.charsLeft = blank ? maxLength : maxLength - value.length;
        }

        if (this.inputType === 'datepicker') {
            const datePickerWrapper = document.querySelector('.profile-form-field__datepicker');
            const icon = document.querySelector('.profile-form-field__calendar-icon');

            // const { firstChild } = datePickerWrapper;
            datePickerWrapper.firstChild.prepend(icon);
        }
    },
};
</script>

<style lang='stylus'>
.profile-form-field {
    --nebula-input-max-width: 1000px;
    --nebula-select-max-width: 1000px;

    flex-basis: 100%;
    width: 100%;

    .nebula-vue-select {
        max-width: var(--nebula-input-max-width);
    }

    &--flex-1 {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex: 1;
        }
    }

    &--flex-2 {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex: 2;
        }
    }

    &--flex-3 {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex: 3;
        }
    }

    &__textarea {
        resize: none;
    }

    &__vue-select {
        max-width: unset;
        text-transform: capitalize;
    }

    &__datepicker {
        --nebula-date-picker-input-padding: 10px;
        position: relative;

        &--error {
            input {
                border-color: var(--nebula-select-border-color-error);
                box-shadow: var(--nebula-select-box-shadow-error);
            }
        }
    }

    &__calendar-icon {
        fill: $nebula-color-platform-interface-800;
        position: absolute;
        right: 16px;
        top: $nebula-space-1x;
    }
}

.careers-form-select {
    background: $nebula-color-platform-white;
    border-radius: $nebula-border-radius-small;
}

.tnc {
    &__group {
        display: flex;
        gap: $nebula-space-2x;

        .nebula-checkbox__container {
            margin: 0;
        }
    }

    &__checkbox {
        margin-bottom: 0;

        .nebula-checkbox__visual {
            border: 2px solid $nebula-color-platform-interface-1000;
        }
    }

    &__link {
        color: $nebula-color-platform-interactive-800;
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
