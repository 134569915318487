import {
    draft,
    scheduled,
    completed,
    expired,
    canceled,
    actionRequired,
    awaitingConfirmation,
    pendingMatches,
} from '@/constants';

const stateOptions = [
    { value: 'AL', text: 'AL', fullname: 'Alabama' },
    { value: 'AK', text: 'AK', fullname: 'Alaska' },
    { value: 'AZ', text: 'AZ', fullname: 'Arizona' },
    { value: 'AR', text: 'AR', fullname: 'Arkansas' },
    { value: 'CA', text: 'CA', fullname: 'California' },
    { value: 'CO', text: 'CO', fullname: 'Colorado' },
    { value: 'CT', text: 'CT', fullname: 'Connecticut' },
    { value: 'DC', text: 'DC', fullname: 'District of Columbia' },
    { value: 'DE', text: 'DE', fullname: 'Delaware' },
    { value: 'FL', text: 'FL', fullname: 'Florida' },
    { value: 'GA', text: 'GA', fullname: 'Georgia' },
    { value: 'HI', text: 'HI', fullname: 'Hawaii' },
    { value: 'ID', text: 'ID', fullname: 'Idaho' },
    { value: 'IL', text: 'IL', fullname: 'Illinois' },
    { value: 'IN', text: 'IN', fullname: 'Indiana' },
    { value: 'IA', text: 'IA', fullname: 'Iowa' },
    { value: 'KS', text: 'KS', fullname: 'Kansas' },
    { value: 'KY', text: 'KY', fullname: 'Kentucky' },
    { value: 'LA', text: 'LA', fullname: 'Louisiana' },
    { value: 'ME', text: 'ME', fullname: 'Maine' },
    { value: 'MD', text: 'MD', fullname: 'Maryland' },
    { value: 'MA', text: 'MA', fullname: 'Massachusetts' },
    { value: 'MI', text: 'MI', fullname: 'Michigan' },
    { value: 'MN', text: 'MN', fullname: 'Minnesota' },
    { value: 'MS', text: 'MS', fullname: 'Mississippi' },
    { value: 'MO', text: 'MO', fullname: 'Missouri' },
    { value: 'MT', text: 'MT', fullname: 'Montana' },
    { value: 'NE', text: 'NE', fullname: 'Nebraska' },
    { value: 'NV', text: 'NV', fullname: 'Nevada' },
    { value: 'NH', text: 'NH', fullname: 'New Hampshire' },
    { value: 'NJ', text: 'NJ', fullname: 'New Jersey' },
    { value: 'NM', text: 'NM', fullname: 'New Mexico' },
    { value: 'NY', text: 'NY', fullname: 'New York' },
    { value: 'NC', text: 'NC', fullname: 'North Carolina' },
    { value: 'ND', text: 'ND', fullname: 'North Dakota' },
    { value: 'OH', text: 'OH', fullname: 'Ohio' },
    { value: 'OK', text: 'OK', fullname: 'Oklahoma' },
    { value: 'OR', text: 'OR', fullname: 'Oregon' },
    { value: 'PA', text: 'PA', fullname: 'Pennsylvania' },
    { value: 'RI', text: 'RI', fullname: 'Rhode Island' },
    { value: 'SC', text: 'SC', fullname: 'South Carolina' },
    { value: 'SD', text: 'SD', fullname: 'South Dakota' },
    { value: 'TN', text: 'TN', fullname: 'Tennessee' },
    { value: 'TX', text: 'TX', fullname: 'Texas' },
    { value: 'UT', text: 'UT', fullname: 'Utah' },
    { value: 'VT', text: 'VT', fullname: 'Vermont' },
    { value: 'VA', text: 'VA', fullname: 'Virginia' },
    { value: 'WA', text: 'WA', fullname: 'Washington' },
    { value: 'WV', text: 'WV', fullname: 'West Virginia' },
    { value: 'WI', text: 'WI', fullname: 'Wisconsin' },
    { value: 'WY', text: 'WY', fullname: 'Wyoming' },
];

function pronounsOptions() {
    return [
        { value: 'he', text: this.$t('pronouns-he', { ns: 'dropdown' }) },
        { value: 'she', text: this.$t('pronouns-she', { ns: 'dropdown' }) },
        { value: 'they', text: this.$t('pronouns-they', { ns: 'dropdown' }) },
    ];
}
function raceOptions() {
    return [
        { value: 'americanindian', text: this.$t('race-american-indian', { ns: 'dropdown' }) },
        { value: 'asian', text: this.$t('race-asian', { ns: 'dropdown' }) },
        { value: 'black', text: this.$t('race-black', { ns: 'dropdown' }) },
        { value: 'hispaniclatino', text: this.$t('race-hispanic-latino', { ns: 'dropdown' }) },
        { value: 'multiple', text: this.$t('race-multiple', { ns: 'dropdown' }) },
        { value: 'hawaiian', text: this.$t('race-hawaiian', { ns: 'dropdown' }) },
        { value: 'white', text: this.$t('race-white', { ns: 'dropdown' }) },
        { value: 'other', text: this.$t('race-other', { ns: 'dropdown' }) },
    ];
}
function genderOptions() {
    return [
        { value: 'female', text: this.$t('gender-female', { ns: 'dropdown' }) },
        { value: 'male', text: this.$t('gender-male', { ns: 'dropdown' }) },
        { value: 'other', text: this.$t('gender-other', { ns: 'dropdown' }) },
    ];
}
function languageOptions() {
    return [
        { value: 'english', label: this.$t('languages-english', { ns: 'dropdown' }) },
        { value: 'mandarin', label: this.$t('languages-mandarin', { ns: 'dropdown' }) },
        { value: 'spanish', label: this.$t('languages-spanish', { ns: 'dropdown' }) },
        { value: 'french', label: this.$t('languages-french', { ns: 'dropdown' }) },
        { value: 'arabic', label: this.$t('languages-arabic', { ns: 'dropdown' }) },
        { value: 'hindi', label: this.$t('languages-hindi', { ns: 'dropdown' }) },
        { value: 'russian', label: this.$t('languages-russian', { ns: 'dropdown' }) },
        { value: 'portuguese', label: this.$t('languages-portuguese', { ns: 'dropdown' }) },
        { value: 'japanese', label: this.$t('languages-japanese', { ns: 'dropdown' }) },
        { value: 'german', label: this.$t('languages-german', { ns: 'dropdown' }) },
        { value: 'italian', label: this.$t('languages-italian', { ns: 'dropdown' }) },
        { value: 'javanese', label: this.$t('languages-javanese', { ns: 'dropdown' }) },
        { value: 'korean', label: this.$t('languages-korean', { ns: 'dropdown' }) },
        { value: 'turkish', label: this.$t('languages-turkish', { ns: 'dropdown' }) },
        { value: 'farsi', label: this.$t('languages-farsi', { ns: 'dropdown' }) },
        { value: 'urdu', label: this.$t('languages-urdu', { ns: 'dropdown' }) },
        { value: 'dutch', label: this.$t('languages-dutch', { ns: 'dropdown' }) },
        { value: 'thai', label: this.$t('languages-thai', { ns: 'dropdown' }) },
        { value: 'bengali', label: this.$t('languages-bengali', { ns: 'dropdown' }) },
        { value: 'vietnamese', label: this.$t('languages-vietnamese', { ns: 'dropdown' }) },
        { value: 'gujarati', label: this.$t('languages-gujarati', { ns: 'dropdown' }) },
    ];
}
function industryOptions() {
    return [
        { value: 'agriculture', text: this.$t('industries-agriculture', { ns: 'dropdown' }) },
        { value: 'architecture', text: this.$t('industries-architecture', { ns: 'dropdown' }) },
        { value: 'arts', text: this.$t('industries-arts', { ns: 'dropdown' }) },
        { value: 'business', text: this.$t('industries-business', { ns: 'dropdown' }) },
        { value: 'education', text: this.$t('industries-education', { ns: 'dropdown' }) },
        { value: 'finance', text: this.$t('industries-finance', { ns: 'dropdown' }) },
        { value: 'government', text: this.$t('industries-government', { ns: 'dropdown' }) },
        { value: 'healthScience', text: this.$t('industries-health-science', { ns: 'dropdown' }) },
        { value: 'hospitality', text: this.$t('industries-hospitality', { ns: 'dropdown' }) },
        { value: 'it', text: this.$t('industries-it', { ns: 'dropdown' }) },
        { value: 'law', text: this.$t('industries-law', { ns: 'dropdown' }) },
        { value: 'manufacturing', text: this.$t('industries-manufacturing', { ns: 'dropdown' }) },
        { value: 'marketing', text: this.$t('industries-marketing', { ns: 'dropdown' }) },
        { value: 'science', text: this.$t('industries-science', { ns: 'dropdown' }) },
        { value: 'transportation', text: this.$t('industries-transportation', { ns: 'dropdown' }) },
    ];
}
function employmentLevelOptions() {
    return [
        { value: 'early', text: this.$t('employment-level-early', { ns: 'dropdown' }) },
        { value: 'intermediate', text: this.$t('employment-level-intermediate', { ns: 'dropdown' }) },
        { value: 'manager', text: this.$t('employment-level-manager', { ns: 'dropdown' }) },
        { value: 'director', text: this.$t('employment-level-director', { ns: 'dropdown' }) },
        { value: 'executive', text: this.$t('employment-level-executive', { ns: 'dropdown' }) },
    ];
}
function tenureOptions() {
    return [
        { value: 'underyear', text: this.$t('tenure-under-year', { ns: 'dropdown' }) },
        { value: 'one', text: this.$t('tenure-one', { ns: 'dropdown' }) },
        { value: 'three', text: this.$t('tenure-three', { ns: 'dropdown' }) },
        { value: 'six', text: this.$t('tenure-six', { ns: 'dropdown' }) },
        { value: 'eleven', text: this.$t('tenure-eleven', { ns: 'dropdown' }) },
        { value: 'fifteenplus', text: this.$t('tenure-fifteen-plus', { ns: 'dropdown' }) },
    ];
}

function tagOptions() {
    return [
        { value: 'general', text: 'General' },
        { value: 'life-science', text: 'Life Science' },
        { value: 'physical-science', text: 'Physical Science' },
        { value: 'earth-science', text: 'Earth and Space Sciences' },
        { value: 'engineering', text: 'Engineering and Manufacturing' },
        { value: 'mathematics', text: 'Mathematics' },
        { value: 'data', text: 'Data Analysis and Data Modeling' },
        { value: 'computer-science', text: 'Computer Science and Mixed Reality' },
        { value: 'health', text: 'Health and Wellness' },
        { value: 'english', text: 'English Language Arts' },
        { value: 'visual-arts', text: 'Visual and Performing Arts' },
        { value: 'social-studies', text: 'Social Studies and Humanities' },
        { value: 'government', text: 'Government and Public Policy' },
        { value: 'financial', text: 'Financial Literacy' },
        { value: 'business', text: 'Business Education' },
    ];
}

const gradeLevelOptions = [
    { value: 'k', text: 'Kindergarten' },
    { value: '1', text: '1st Grade' },
    { value: '2', text: '2nd Grade' },
    { value: '3', text: '3rd Grade' },
    { value: '4', text: '4th Grade' },
    { value: '5', text: '5th Grade' },
    { value: '6', text: '6th Grade' },
    { value: '7', text: '7th Grade' },
    { value: '8', text: '8th Grade' },
    { value: '9', text: '9th Grade' },
    { value: '10', text: '10th Grade' },
    { value: '11', text: '11th Grade' },
    { value: '12', text: '12th Grade' },
];

const requestStatusOptions = [
    { value: draft, text: 'Draft' }, // Not ready to be seen by anyone
    { value: scheduled, text: 'Scheduled' }, // Employee and Educator are matched
    { value: expired, text: 'Expired' }, // Request was never scheduled but time has passed
    { value: completed, text: 'Completed' }, // Request has finished (meeting done)
    { value: canceled, text: 'Canceled' }, // Request no longer happening
    { value: pendingMatches, text: 'Pending Matches' }, // Request has been submitted
    { value: actionRequired, text: 'Action Required' }, // An employee has applied to a request
    { value: awaitingConfirmation, text: 'Awaiting Confirmation' }, // Request has been submitted
];

function timeSlotOptions() {
    return [
        {
            value: 'early-morning',
            label: '6:00am-8:00am',
        },
        {
            value: 'morning',
            label: '8:00am-10:00am',
        },
        {
            value: 'late-morning',
            label: '10:00am-12:00pm',
        },
        {
            value: 'afternoon',
            label: '12:00pm-2:00pm',
        },
        {
            value: 'late-afternoon',
            label: '2:00pm-5:00pm',
        },
        {
            value: 'evening',
            label: '5:00pm-8:00pm',
        },
    ];
}

const typeOptions = [
    { value: '1', text: 'Share my Career' },
    { value: '2', text: 'Custom Request' },
];

// eslint-disable-next-line arrow-body-style
const applicantDeclineReasons = () => {
    return [
        {
            value: 'not-a-fit',
            label: 'Industry/Area of expertise is not a fit for my request',
        },
        {
            value: 'prefer-local',
            label: 'Prefer a career connection local to my area',
        },
        {
            value: 'different-applicant',
            label: 'A different applicant is a better fit',
        },
        {
            value: 'will-follow',
            label: 'I am planning on sending a new direct request to this applicant for something else',
        },
        {
            value: 'other',
            label: 'Other reason',
        },
    ];
};

export {
    applicantDeclineReasons,
    stateOptions,
    pronounsOptions,
    raceOptions,
    genderOptions,
    gradeLevelOptions,
    languageOptions,
    industryOptions,
    employmentLevelOptions,
    tenureOptions,
    tagOptions,
    typeOptions,
    timeSlotOptions,
    requestStatusOptions,
};
