import axios from 'axios';

const API_DOMAIN = process.env.VUE_APP_API_URL;

// Base GET actions for loading and storing user data globally
const getNotifications = async ({ commit, getters }, payload) => {
    try {
        const response = await axios.get(`${API_DOMAIN}/notifications`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });

        // Sort notifications
        let notifications = response.data.records || [];
        notifications = notifications.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1));
        const now = new Date().getTime() / 1000;

        const daysToExpire = 14;
        const daysToExpireUnread = 30;
        const expiryOnRead = now - (60 * 60 * 24 * daysToExpire);
        const expiryOnUnread = now - (60 * 60 * 24 * daysToExpireUnread);

        // remove notifications for flagged features
        const userFlags = getters.flags;
        // key is notification type/title, value is flag name
        const notificationFlags = {
            new_message: 'messaging',
        };

        notifications = notifications.filter((notification) => {
            const { read, createdDate, title } = notification;

            // remove notifications for features that are not enabled
            const flaggedNotifications = Object.keys(notificationFlags);
            const notificationTitle = title.toLowerCase().trim();
            if (flaggedNotifications.includes(notificationTitle)) {
                const flagToCheck = notificationFlags[notificationTitle];
                return userFlags[flagToCheck];
            }

            if (read) {
                return expiryOnRead < createdDate;
            }

            return expiryOnUnread < createdDate;
        });

        commit('UPDATE_APP', { notifications });
    } catch (error) {
        console.log(error);
        console.log(payload);
    }
};

const getNotification = async ({ commit }, payload) => {
    try {
        const response = await axios.get(`${API_DOMAIN}/notifications/${payload.data}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
        });

        commit('SET_NOTIFICATION', response.data);
        return response.data;
    } catch (error) {
        console.log(error);
        console.log(payload);
    }
    return '';
};

const updateNotification = async (context, payload) => {
    try {
        await axios.post(`${API_DOMAIN}/notifications/${payload.data.notificationId}`,
            payload.data,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`,
                },
            });
    } catch (error) {
        console.log(error);
        console.log(payload);
    }
    return '';
};

export default {
    getNotifications,
    getNotification,
    updateNotification,
};
