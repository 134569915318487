function calculateTime(date) {
    // compare current time to date passed in, parse out days, hours, minutes remaining
    const now = new Date() / 1000;
    let past = false;

    let timeDiff = Math.round(date - now);

    // check if comparison is in the past
    if (timeDiff < 0) {
        past = true;
        // default to number of minutes ago
        timeDiff = Math.round(now - date);
    }
    const weeksDiff = Math.floor(timeDiff / 60 / 60 / 24 / 7);
    const daysDiff = Math.floor(timeDiff / 60 / 60 / 24);
    let hoursDiff = Math.floor(timeDiff / 60 / 60);
    let minsDiff = Math.ceil(timeDiff / 60);

    let monthDiff;
    let yearDiff;

    // timeDiff is less than a minute
    if (timeDiff < 45) {
        return {
            mins: 0,
            past,
        };
    }

    if (weeksDiff > 5) {
        const dateInput = new Date(date * 1000);
        const nowDate = new Date();

        const compareMonth = dateInput.getMonth();
        const currentMonth = nowDate.getMonth();

        const compareYear = dateInput.getFullYear();
        const currentYear = nowDate.getFullYear();

        if (compareYear !== currentYear) {
            yearDiff = currentYear - compareYear;

            monthDiff = (12 - compareMonth) + ((yearDiff - 1) * 12) + currentMonth;
        }
        if (compareMonth > currentMonth) {
            monthDiff = (12 - compareMonth) + currentMonth;
        }
    }

    if (daysDiff > 0) {
        timeDiff -= (daysDiff * 86400);
        hoursDiff = Math.floor(timeDiff / 60 / 60);
    }
    if (hoursDiff > 0) {
        timeDiff -= (hoursDiff * 3600);
        minsDiff = Math.ceil(timeDiff / 60);
    }

    return {
        years: yearDiff,
        months: monthDiff,
        weeks: weeksDiff,
        days: daysDiff,
        hours: hoursDiff,
        mins: minsDiff,
        past,
    };
}

function timeFromNow(date) {
    const {
        years,
        months,
        weeks,
        days,
        hours,
        mins,
        past,
    } = calculateTime(date);
    const strings = [];

    if (mins < 1) {
        strings.push(past && 'Just now');
    } else if (years > 1) {
        strings.push(`${years} 'years'`);
    } else if (months > 1) {
        strings.push(`${months} months`);
    } else if (weeks) {
        strings.push(`${weeks} ${weeks > 1 ? 'weeks' : 'week'}`);
    } else if (days) {
        strings.push(`${days} ${days > 1 ? 'days' : 'day'}`);
    } else if (hours) {
        strings.push(`${hours} ${hours > 1 ? 'hours' : 'hour'}`);
    } else if (mins) {
        strings.push(`${mins} ${mins > 1 ? 'minutes' : 'minute'}`);
    }

    const timeStringResult = strings.join(', ');
    if (past && mins > 1) {
        return [timeStringResult, 'ago'].join(' ');
    }

    return timeStringResult;
}

export default timeFromNow;
