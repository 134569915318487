<template>
    <FocusTrap
        :active="focusTrapActive"
        :escapeDeactivates="false"
        initialFocus=".careers-global-nav-item__link"
    >
        <nav
            :aria-label="$t('Global Navigation')"
            aria-modal="true"
            class="careers-global-nav"
            :class="!isOpen && 'careers-global-nav--closed'"
            :id="id"
            role="dialog"
            tabindex="0"
            @keydown="handleKeydown"
        >
            <div class="careers-global-nav__top">
                <RouterLink
                    v-if="routerLink"
                    :aria-label="$t('Home')"
                    :to="homeLink"
                    ref="home"
                    class="nebula-product-bar__home"
                >
                    <img
                        src="@/assets/de-logo.png"
                        :alt="$t('Discovery Education Logo')"
                    >
                    <img
                        src="@/assets/career-connect-logo.svg"
                    >
                </RouterLink>
                <a
                    v-else
                    :aria-label="$t('Home')"
                    :href="homeLink"
                    ref="home"
                    class="career-global-nav__logo-lockup"
                >
                    <img
                        src="@/assets/de-logo.png"
                        :alt="$t('Discovery Education Logo')"
                    >
                    <span class="career-global-nav__bar" />
                    <img
                        src="@/assets/career-connect-logo.svg"
                        :alt="$t('Career Connect logo')"
                    >
                </a>
                <NebulaButton
                    iconLeft="x"
                    size="s"
                    type="flat"
                    class="careers-global-nav__close"
                    aria-label="close global navigation"
                    @click="handleClose"
                />
            </div>

            <section v-for="(section, idx) in data" :key="idx" class="careers-global-nav__section">
                <h2 class="careers-global-nav__header">{{ $t(`${section.name}`) }}</h2>
                <GlobalNavItem
                    v-for="(link, linkIdx) in section.links"
                    :key="linkIdx"
                    :data="link"
                />
            </section>
            <!--
                Once the loading spinner is complete it might make sense
                to add it here with v-else
            -->
        </nav>
    </FocusTrap>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { FocusTrap } from 'focus-trap-vue';
import { NebulaButton } from '@discoveryedu/nebula-components';
import GlobalNavItem from '@/components/shared/GlobalNavItem.vue';

export default {
    name: 'NebulaGlobalNav',
    components: {
        FocusTrap,
        NebulaButton,
        GlobalNavItem,
    },
    props: {
        data: {
            type: Array,
        },
        focusTrapEnabled: {
            type: Boolean,
            default: false,
        },
        homeLink: {
            type: String,
            default: '/suite/',
        },
        id: {
            type: String,
            default: 'nebula-global-nav',
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        routerLink: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClose() {
            this.$emit('toggle-global-nav', false);

            // revert focus to previously stored activeElement when global nav closes
            if (this.prevFocus) {
                this.prevFocus.focus();
            }
        },
        handleKeydown(e) {
            if (e.code === 'Escape') {
                this.handleClose();
            }
        },
        focusOnOpen() {
            // store current activeElement before changing focus
            this.prevFocus = document.activeElement;

            const homeRef = this.$refs?.home?.$el || this.$refs.home;

            if (!homeRef) {
                return;
            }

            homeRef.focus();
        },
    },
    computed: {
        focusTrapActive() {
            // criteria for FocusTrap to be enabled

            // user/dev must not have disabled it via the focusTrapEnabled prop
            if (!this.focusTrapEnabled) {
                return false;
            }

            // data must be present so sections are painted
            // isVisible state must be truthy (bound to transition timing on global nav)
            return !!this.data && !!this.data.navigation && this.isVisible;
        },
    },
    data() {
        return {
            prevFocus: null,
            isVisible: false,
        };
    },
    watch: {
        isOpen(openVal) {
            // Set timeout used to ensure the panel is visible
            // before triggering the focus trap, otherwise focus-trap doesn't
            // detect any tabbable elements and throws an error.
            setTimeout(() => {
                this.isVisible = openVal;
            }, 300);
        },
    },
};
</script>

<style lang="stylus">
    :root {
        --careers-global-nav-background: $nebula-color-platform-white;
        --careers-global-nav-box-shadow: 0 4px 106px 5px rgba(0, 0, 0, .2);
        --careers-global-nav-border: none;
        --careers-global-nav-padding: $nebula-space-3x;
        --careers-global-nav-transition: $nebula-transition-default;
        --careers-global-nav-width: 350px;
        --careers-global-nav-hairline: 1px solid $nebula-color-interface-blue-100;

        --careers-global-nav-header-color: $nebula-color-platform-neutral-900;
        --careers-global-nav-header-margin: 0 0 $nebula-space-2x 0;
        --careers-global-nav-header-padding: 0 $nebula-space-2x;
    }

    icon-wrapper() {
        border-radius: $nebula-border-radius-badge-default;
        display: inline-block;
        padding: $nebula-space-half;
        line-height: 0;
    }

    .careers-global-nav {
        background: var(--nebula-global-nav-background);
        box-shadow: var(--nebula-global-nav-box-shadow);
        border-inline-end: var(--nebula-global-nav-border);
        height: 100%;
        inset-inline-start: 0;
        overflow-y: auto;
        padding: var(--nebula-global-nav-padding);
        position: fixed;
        top: 0;
        z-index: 10;
        transition:
            inset-inline-start var(--nebula-global-nav-transition) .1s,
            box-shadow var(--nebula-global-nav-transition) .1s,
            visibility .05s ease-in-out;
        width: var(--nebula-global-nav-width);

        &::-webkit-scrollbar {
            display: none;
        }

        &__header {
            nebula-text-body-1();
            nebula-text-semibold();

            color: $nebula-color-platform-neutral-900;
            margin: 0 0 $nebula-space-2x 0;
            padding: 0 $nebula-space-2x;
        }

        &__top {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: $nebula-space-5x;
            margin-right: -8px;
        }

        &__close {
            background: none;
            border: none;
            line-height: 0;
            .nebula-button__icon {
                fill: $nebula-color-interface-blue-600;
            }
        }

        &__section {
            padding-bottom: $nebula-space-3x;
            margin-bottom: $nebula-space-3x;
            border-bottom: var(--nebula-global-nav-hairline);
            &:last-child {
                border: none;
                margin: 0;
                padding: 0;
            }
        }

        &--closed {
            box-shadow: 0 4px 106px 5px rgba(0, 0, 0, 0);
            inset-inline-start: calc(var(--nebula-global-nav-width) * -1);
            visibility: hidden;
            transition:
                inset-inline-start $nebula-transition-default,
                box-shadow $nebula-transition-default,
                visibility $nebula-transition-default .3s;
        }
    }

    .icon-wrapper {
        icon-wrapper();
        svg {
            fill: $nebula-color-platform-interactive-1000;
        }
    }

    .career-global-nav {
        &__bar {
            width: 1px;
            height: 21px;
            background: $nebula-color-platform-interface-500;
            margin-inline: $nebula-space-1x;
        }
        &__logo-lockup {
            display: flex;
            align-items: center;
        }
    }
</style>
