<template>
    <transition v-if="showModal" name="nebula-modal-transition" mode="out-in">
        <NebulaModal
            @close-modal="toggleUploadModal"
            ariaLabelledby="profile-setup-modal__title"
            firstFocusedElement="button-close"
            id="profile-setup-modal"
            size="m"
            showHeader
            class="profile-setup-modal"
            ref="myModal"
        >
            <template v-slot:header>
                <h2
                    id="profile-setup-modal__title"
                    class="profile-setup-modal__title"
                >
                    <span v-if="modalType === 'upload'">
                        {{ $t('upload-photo', { ns: 'profile' }) }}
                    </span>
                    <span v-else-if="modalType === 'edit'">
                        {{ $t('change-photo', { ns: 'profile' }) }}
                    </span>
                </h2>
            </template>

            <template v-slot:content>
                <!-- Container -->
                <div class="profile-setup-modal__content-container">
                    <!-- Content -->
                    <div class="profile-setup-modal__content">
                        <!-- Description -->
                        <div class="profile-setup-modal__description">
                            <div v-if="showCropper">
                                {{ $t('zoom-to-crop', { ns: 'profile' }) }}
                            </div>
                        </div>
                        <!-- Cropper -->
                        <div
                            v-if="modalType === 'edit' || showCropper"
                            class="profile-setup-modal__cropper"
                            :style="cssVars"
                        >
                            <cropper
                                stencil-component="circle-stencil"
                                ref="cropper"
                                image-restriction="stencil"
                                :stencil-size="stencilSize"
                                :stencil-props="{
                                    lines: {},
                                    handlers: {},
                                    movable: false,
                                    scalable: false,
                                    aspectRatio: 1,
                                    class: 'vue-advanced-cropper__stencil'
                                  }"
                                :transitions="false"
                                :canvas="{
                                    maxArea: (imageCropSize * 2) * (imageCropSize * 2)
                                }"
                                :debounce="true"
                                :default-size="defaultSize"
                                :min-width="imageCropSize"
                                :min-height="imageCropSize"
                                :src="image.src"
                                @change="onChange"
                            />
                            <div v-if="modalType === 'edit' || showZoom" class="profile-setup-modal__settings">
                                <div class="profile-setup-modal__zoom">
                                    {{ isMobile ? $t('pinch-zoom', { ns: 'profile' }) : $t('zoom-crop', { ns: 'profile' }) }}
                                </div>
                                <ProfileSetupZoom :zoom="zoom" @change="onZoom" />
                            </div>
                        </div>
                        <!-- Helpers -->
                        <div class="profile-setup-modal__helpers">
                            <!-- Meta -->
                            <div class="profile-setup-modal__meta">
                                <p><strong>{{ $t('file-type-supports', { ns: 'profile' }) }}</strong></p>
                                <p>
                                    {{ $t('min-max-image',
                                        {
                                            fileMinDimension: fileMinDimension,
                                            fileSizeLimit: fileSizeFormat(fileSizeLimit),
                                            ns: 'profile'
                                        })
                                    }}
                                </p>
                            </div>
                            <!-- Validation -->
                            <div v-if="showValidation" class="profile-setup-modal__validation">
                                <span
                                    class="profile-setup-modal__validation-item"
                                >
                                    <NebulaIcon
                                        class="profile-setup-modal__validation-icon"
                                        symbolId="warning--filled"
                                        size="s"
                                    />
                                    <p>{{ $t('file-too-large', { ns: 'profile' }) }}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actionsRow>
                <div class="profile-setup-modal__button-wrapper">
                    <NebulaButton
                        id="button-close"
                        @click="toggleUploadModal"
                        type="flat"
                        size="m"
                        :text="$t('cancel')"
                        :aria-label="$t('cancel')"
                    />
                </div>

                <div class="profile-setup-modal__button-wrapper">
                    <div class="profile-setup-modal__button-upload">
                        <NebulaButton
                            ref="buttonUpload"
                            :icon-left="isMobile ? '' : 'upload'"
                            type="ghost"
                            size="m"
                            :text="$t('upload')"
                            :aria-label="$t('upload-photo')"
                            @click="$refs.file.click()"
                        />
                        <input
                            type="file"
                            ref="file"
                            @change="uploadImage($event)"
                            accept="image/jpeg, image/png"
                        >
                        <label for="upload" class="nebula-screenreader-only">{{ $t('upload') }}</label>
                    </div>

                    <template v-if="modalType === 'upload'">
                        <NebulaButton
                            v-if="showCropper"
                            :disabledLink="disabledSave"
                            ref="buttonSave"
                            @click="cropAndSaveImage()"
                            size="m"
                            :text="$t('save')"
                            :aria-label="$t('save')"
                        />
                    </template>
                    <template v-if="modalType === 'edit'">
                        <NebulaButton
                            ref="buttonSave"
                            @click="cropAndSaveImage()"
                            size="m"
                            :text="$t('save')"
                            :aria-label="$t('save')"
                        />
                    </template>
                </div>
            </template>
        </NebulaModal>
    </transition>
</template>

<script>
import { NebulaButton, NebulaIcon, NebulaModal } from '@discoveryedu/nebula-components';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import breakpoints from '@/mixins/breakpoints';
import createPayload from '@/mixins/data/createPayload';
import {
    roles,
} from '@/constants';

import ProfileSetupZoom from './ProfileSetupZoom.vue';

export default {
    name: 'ProfileSetupModal',
    components: {
        NebulaButton,
        NebulaIcon,
        NebulaModal,
        Cropper,
        ProfileSetupZoom,
    },
    data() {
        return {
            imageCropSize: 224,
            fileMinDimension: 400,
            fileSizeLimit: 5000000,
            showZoom: false,
            showValidation: false,
            showCropper: false,
            disabledSave: true,
            modalType: 'edit',
            zoom: 0,
            image: {
                src: '/img/img-profile-setup-default.png',
                type: 'image/jpg',
            },

        };
    },
    mixins: [breakpoints, createPayload],
    methods: {
        toggleUploadModal() {
            this.$store.dispatch('toggleModal', {
                category: 'settings',
                type: 'upload_image',
                show: !this.$store.getters.avatarUploadModal.show,
            });
        },
        async cropAndSaveImage() {
            const payload = await this.createPayload();
            const result = this.$refs.cropper.getResult();
            const file = {
                fileContent: result.canvas.toDataURL(this.image.type),
            };
            payload.data = file;

            const uploadActions = {
                [roles.EDUCATOR_ROLE]: 'updateEducatorAvatar',
                [roles.EMPLOYEE_ROLE]: 'updateEmployeeAvatar',
                [roles.ORGADMIN_ROLE]: 'updateOrgAvatar',
            };

            if (Object.keys(uploadActions).includes(this.mode)) {
                this.$store.dispatch(uploadActions[this.mode], payload);
            }

            this.toggleUploadModal();
        },
        fileSizeFormat(bytes) {
            return `${(bytes / 1000000).toFixed(0)}MB`;
        },
        uploadImage(event) {
            /// Reference to the DOM input element
            const { files } = event.target;
            // Ensure that you have a file before attempting to read it
            if (files && files[0]) {
                // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src);
                }
                // 2. Create the blob link to the file to optimize performance:
                const blob = URL.createObjectURL(files[0]);

                // Set file and get sizing
                const file = files[0];
                const fileSize = file.size;

                // File size calculation
                if (fileSize > this.fileSizeLimit) {
                    this.showCropper = false;
                    this.showZoom = false;
                    this.showValidation = true;
                    this.disabledSave = true;
                } else {
                    // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
                    this.image = {
                        src: blob,
                        type: files[0].type,
                    };

                    this.showCropper = true;
                    this.showZoom = true;
                    this.showValidation = false;
                    this.disabledSave = false;
                }
            }
        },
        defaultSize({ imageSize }) {
            return {
                width: Math.min(imageSize.height, imageSize.width),
                height: Math.min(imageSize.height, imageSize.width),
            };
        },
        stencilSize({ boundaries }) {
            return {
                width: Math.min(boundaries.height, boundaries.width),
                height: Math.min(boundaries.height, boundaries.width),
            };
        },
        // closure for "result" available here. Removed because not in use
        onChange() {
            const { cropper } = this.$refs;
            if (cropper) {
                const { coordinates, imageSize } = cropper;
                if (
                    imageSize.width / imageSize.height
                    > coordinates.width / coordinates.height
                ) {
                    // Determine the position of slider bullet
                    // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
                    this.zoom = (cropper.imageSize.height - cropper.coordinates.height)
                        / (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
                } else {
                    this.zoom = (cropper.imageSize.width - cropper.coordinates.width)
                        / (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
                }
            }
        },
        onZoom(value) {
            const { cropper } = this.$refs;
            if (cropper) {
                if (cropper.imageSize.height < cropper.imageSize.width) {
                    const { minHeight } = cropper.sizeRestrictions;
                    const imageHeight = cropper.imageSize.height;
                    // Determine the current absolute zoom and the new absolute zoom
                    // to calculate the sought relative zoom value
                    cropper.zoom(
                        (imageHeight - this.zoom * (imageHeight - minHeight))
                        / (imageHeight - value * (imageHeight - minHeight)),
                    );
                } else {
                    const { minWidth } = cropper.sizeRestrictions;
                    const imageWidth = cropper.imageSize.width;
                    cropper.zoom(
                        (imageWidth - this.zoom * (imageWidth - minWidth))
                        / (imageWidth - value * (imageWidth - minWidth)),
                    );
                }
            }
        },
    },
    computed: {
        cssVars() {
            return {
                '--imageCropSize': (`${this.imageCropSize}px`),
            };
        },
        showModal() {
            return this.$store.getters.avatarUploadModal.show;
        },

        mode() {
            return this.$store.getters.getMode;
        },
    },
    unmounted() {
        // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
            URL.revokeObjectURL(this.image.src);
        }
    },
};
</script>

<style lang='stylus'>
/* Profile Setup Modal */
.profile-setup-modal {
    p {
        color: $nebula-color-platform-neutral-1000;
    }

    &__cropper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: $nebula-space-3x auto;
        max-width: var(--imageCropSize);

        @media (min-width: $nebula-breakpoints-mobile-landscape) {
            margin: $nebula-space-5x auto $nebula-space-1x;
        }
    }

    &__helpers {
        margin-top: $nebula-space-2x;
    }

    &__meta {
        font-size: $nebula-font-size-body-2;

        p {
            margin: 0
        }
    }

    &__validation {
        display: flex;
        flex-direction: column;
        gap: $nebula-space-1x;
        margin-top: $nebula-space-3x;

        &-item {
            align-items: center;
            background-color: $nebula-color-feedback-error-50;
            border-radius: $nebula-border-radius-small;
            display: flex;
            gap: $nebula-space-1x + $nebula-space-half;
            justify-content: center;
            line-height: $nebula-font-line-height-heading;
            padding: $nebula-space-1x + $nebula-space-half;
            text-align: start;

            p {
                color: $nebula-color-platform-neutral-900;
                font-size: $nebula-font-size-body-2;
                line-height: 1.3;
                margin: 0;
            }
        }

        &-icon {
            fill: $nebula-color-feedback-error-600;
            flex-shrink: 0;
        }
    }

    &__settings {
        margin-top: $nebula-space-1x;
    }

    &__zoom {
        color: $nebula-color-platform-interface-900;
        font-weight: 700;
        line-height: 1.6;
    }

    &__button {
        &-upload input {
            display: none;
            visibility: hidden;
        }

        &-wrapper {
            display: flex;
            gap: $nebula-space-1x;
        }
    }

    /* Vue Advanced Cropper */

    .vue-advanced-cropper {
        background-color: $nebula-color-platform-white;
        height: var(--imageCropSize);
        margin: 0 auto;
        overflow: hidden;
        width: var(--imageCropSize);

        &__image-wrapper {
            height: 100%;
            position: absolute;
            width: 100%;
        }

        &__background {
            background-color: $nebula-color-platform-white;
        }

        &__foreground {
            background-color: $nebula-color-platform-white;
            opacity: 0.8;
            transition: opacity $nebula-transition-default;
        }

        &__stencil {
            &:before {
                background-image: url("/img/img-cropper-stencil.svg");
                background-repeat: no-repeat;
                bottom: 0;
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 12;
            }
        }
    }

    /* Nebula */

    .nebula-modal {
        border-radius: $nebula-border-radius-default;

        &__actions-container {
            justify-content: space-between;
            margin-block-start: $nebula-space-3x;
        }

        &__header-container {
            padding: 0;
        }

        &__scroll-container {
            @media (min-width: $nebula-breakpoints-mobile-landscape) {
                max-width: 458px;
            }

            .nebula-modal__inner {
                padding: $nebula-space-2x;
                @media (min-width: $nebula-breakpoints-mobile-landscape) {
                    padding: $nebula-space-3x;
                }
            }
        }
    }

}
</style>
