<template>
    <NebulaPopover
        class="notifications-popover"
        ariaLabel="Notifications"
        id="notificationsPopover"
        placement="bottom"
        ref="notificationsPopover"
    >
        <template v-slot:content>
            <div v-if="!loading" class="notifications-popover__body">
                <div class="notifications-popover__header">
                    <h3 class="notifications-popover__title">
                        {{ $t('Notifications') }}
                    </h3>
                    <NebulaButton
                        v-if="showMarkAll"
                        icon-left="check"
                        size="s"
                        class="notification-card__button"
                        :aria-label="$t('mark-all-read', { ns: 'notifications'})"
                        :text="$t('mark-all-read', { ns: 'notifications'})"
                        type="flat"
                        @click="markAllRead"
                    />
                </div>

                <div
                    v-if="notifications && notifications.length > 0"
                    class="notifications-popover__section-scroll"
                >
                    <ul
                        class="notifications-popover__section-list"
                    >
                        <NotificationCard
                            v-for="(notification, idx) in notifications"
                            :key="idx"
                            :asset="notification"
                            @close-notifications="closeNotifications"
                        />
                    </ul>
                </div>
                <div
                    v-else
                    class="notifications-popover__section-container"
                >
                    {{ $t('No Notifications') }}
                </div>
            </div>
        </template>
        <template v-slot:trigger="{ togglePopover, popoverOpen }">
            <NebulaTitleTooltip
                position="block-end"
                :text="$t('Open Notifications')"
            >
                <NebulaButton
                    :aria-label="$t('Open Notifications')"
                    :aria-expanded="popoverOpen && 'true' || 'false'"
                    aria-haspopup="true"
                    id="nebula-popover-trigger__notificationsPopover"
                    class="nebula-popover__trigger notifications-popover__count"
                    icon-left="notifications"
                    type="flat"
                    :text="notificationsCount"
                    @click="togglePopover"
                    size="s"
                />
            </NebulaTitleTooltip>
        </template>
    </NebulaPopover>
</template>

<script>
import { NebulaButton, NebulaPopover, NebulaTitleTooltip } from '@discoveryedu/nebula-components';
import NotificationCard from '@/components/shared/cards/NotificationCard.vue';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'NotificationsPopover',
    components: {
        NebulaButton,
        NebulaPopover,
        NotificationCard,
        NebulaTitleTooltip,
    },
    methods: {
        closeNotifications() {
            this.$refs.notificationsPopover.togglePopover();
        },
        async markAllRead() {
            const toUpdate = this.notifications.filter((notification) => !notification.read);

            await Promise.all(
                toUpdate.forEach(async (each) => {
                    const notification = each;
                    notification.read = true;
                    const payload = await this.createPayload(notification);
                    return this.$store.dispatch('updateNotification', payload);
                }),
            );

            const payload = await this.createPayload();
            await this.$store.dispatch('getNotifications', payload);
        },
    },
    computed: {
        notificationsCount() {
            return this.notifications ? this.notifications.filter((each) => !each.read).length : 0;
        },
        notifications() {
            return this.$store.state.app.notifications;
        },
        showMarkAll() {
            if (!this.notifications || this.notifications.length < 1) {
                return false;
            }

            const unread = this.notifications.filter((each) => !each.read);
            if (unread.length < 1) {
                return false;
            }

            return true;
        },
    },
    mixins: [createPayload],
    props: {
        showNotificationPopover: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
        },
    },
};
</script>

<style lang='stylus'>
.notifications-popover {
    .nebula-popover {
        max-width: 358px;
        min-width: 328px;
        width: 100%;

        @media (min-width: $nebula-breakpoints-tablet-landscape) {
            min-width: 348px;
        }

        &__inner {
            width: 100%;
        }

        &.popper {
            left: -116px !important;
            width: 100%;
            @media (min-width: $nebula-breakpoints-tablet-landscape) {
                left: -123px !important;
            }

            &[x-placement^=bottom] {
                margin-top: $nebula-space-2x;

                .popper__arrow {
                    margin-inline: 83.5%;
                    height: $nebula-space-1x + $nebula-space-half;
                    top: -($nebula-space-1x - 1);
                    width: $nebula-space-1x + $nebula-space-half;
                }
            }

            .nebula-popover__close-button.nebula-button {
                .nebula-button__icon {
                    height: (($nebula-space-1x * 2) + $nebula-space-half);
                    width: (($nebula-space-1x * 2) + $nebula-space-half);
                }
            }

            .popper__arrow {

            }
        }
    }

    &__header {
        align-items: center;
        display: flex;
        gap: $nebula-space-1x
        justify-content: space-between;
        padding: $nebula-space-2x 0;
    }

    &__title {
        font-size: $nebula-font-size-card-title;
        font-weight: 700;
        margin: 0;
    }

    &__content {
        &-container {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    }

    &__button {
        flex: 1;
        justify-content: center;

        &-container {
            display: flex;
            margin: $nebula-space-2x auto $nebula-space-4x;
            width: 100%;
        }
    }

    &__section {
        &-scroll {
            margin: 0 ($nebula-space-2x * -1);
            max-height: 716px;
            overflow-y: auto;
            padding: 0;
        }

        &-list {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        &-title {
            color: $nebula-color-platform-neutral-900;
            font-size: $nebula-font-size-body-1;
            font-weight: 700;
            margin: 0 0 $nebula-space-1x;
        }
    }

    &__count {
        --nebula-button-font-size: $nebula-font-size-body-2;
    }
}

</style>
