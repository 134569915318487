<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';

export default {
    name: 'ProfileSetupZoom',
    components: {
        NebulaIcon,
    },
    props: {
        zoom: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            focus: false,
        };
    },
    mounted() {
        window.addEventListener('mouseup', this.onStop, { passive: false });
        window.addEventListener('mousemove', this.onDrag, { passive: false });
        window.addEventListener('touchmove', this.onDrag, { passive: false });
        window.addEventListener('touchend', this.onStop, { passive: false });
    },
    destroyed() {
        window.removeEventListener('mouseup', this.onStop);
        window.removeEventListener('mousemove', this.onDrag);
        window.removeEventListener('touchmove', this.onDrag);
        window.removeEventListener('touchend', this.onStop);
    },
    methods: {
        onDrag(e) {
            if (this.focus) {
                const position = e.touches ? e.touches[0].clientX : e.clientX;
                const { line } = this.$refs;

                if (line) {
                    const { left, width } = line.getBoundingClientRect();
                    this.$emit(
                        'change',
                        Math.min(1, Math.max(0, position - left) / width),
                    );
                }
                if (e.preventDefault) {
                    e.preventDefault();
                }
            }
        },
        onStop() {
            this.focus = false;
        },
        onStart(e) {
            this.focus = true;
            this.onDrag(e);
        },
    },
};
</script>

<template>
    <div class="profile-setup-zoom">
        <div class="profile-setup-zoom__wrapper">
            <div class="profile-setup-zoom__icon profile-setup-zoom__icon--left">
                <NebulaIcon
                    symbol-id="minus"
                    size="s"
                    class=""
                />
            </div>
            <div
                class="profile-setup-zoom__line-wrapper"
                ref="line"
                @mousedown="onStart"
                @touchstart="onStart"
            >
                <div class="profile-setup-zoom__line">
                    <div class="profile-setup-zoom__fill" :style="{flexGrow: zoom}" />
                    <div
                        class="profile-setup-zoom__circle"
                        :class="{'profile-setup-zoom__circle--focus': focus}"
                        :style="{left: `${zoom * 100}%`}"
                    >
                        <div
                            class="profile-setup-zoom__inner-circle"
                            :class="{'profile-setup-zoom__inner-circle--focus': focus}"
                        />
                    </div>
                </div>
            </div>

            <div class="profile-setup-zoom__icon profile-setup-zoom__icon--right">
                <NebulaIcon
                    symbol-id="plus"
                    size="s"
                    class=""
                />
            </div>
        </div>
    </div>
</template>

<style lang='stylus'>
$inner-circle = 20px;
$outer-circle = 30px;
$icon-size = 18px;

/* Zoom */
.profile-setup-zoom {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: $nebula-space-half;
    width: 100%;

    &__wrapper {
        align-items: center;
        display: flex;
        max-width: 400px;
        width: 100%;
    }

    &__icon {
        fill: $nebula-color-platform-neutral-1000;
        flex-shrink: 0;
        height: $icon-size;
        width: $icon-size;

        &--left {
            margin-inline-end: 10px;
        }

        &--right {
            margin-inline-start: 10px;
        }
    }

    &__line-wrapper {
        align-items: center;
        border-radius: $nebula-border-radius-badge-default;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    &__line {
        align-items: center;
        background-color: $nebula-color-platform-interface-300;
        border-radius: $nebula-border-radius-badge-default;
        display: flex;
        height: $nebula-space-1x;
        position: relative;
        width: 100%;
    }

    &__fill {
        align-self: stretch;
        background-color: $nebula-color-platform-interactive-800;
        border-radius: $nebula-border-radius-badge-default;
        flex-basis: auto;
        flex-direction: column;
        flex-shrink: 0;
    }

    &__circle {
        align-items: center;
        background-color: transparent;
        border-radius: 50%;
        display: flex;
        height: $outer-circle;
        justify-content: center;
        margin-left: -($outer-circle / 2);
        position: absolute;
        transition-duration: 0.2s;
        transition-property: background-color, box-shadow;
        width: $outer-circle;

        &:hover {
            background-color: $nebula-color-platform-interface-200;
        }

        &--focus {
            background-color: $nebula-color-platform-interface-300;
        }
    }

    &__inner-circle {
        background-color: $nebula-color-platform-interactive-800;
        border-radius: 50%;
        border: 2px solid $nebula-color-platform-white;
        height: $inner-circle;
        transform: scale(1);
        transition-duration: 0.1s;
        transition-property: transform;
        width: $inner-circle;

        &--focus {
            transform: scale(1.2);
        }
    }
}
</style>
