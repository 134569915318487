import timeSlotsMap from '@/data/timeSlotsMap';

function in30mins() {
    const now = new Date();
    return new Date(now.getTime() + 30 * 60000);
}

// API expects seconds, JS by default uses milliseconds
function convertToSeconds(timestamp) {
    return Math.floor(timestamp / 1000);
}

function inXdays(days) {
    const now = new Date();

    const inMilliseconds = days * 24 * 60 * 60 * 1000;

    return convertToSeconds(now.getTime() + inMilliseconds);
}

function timestampNow() {
    const now = new Date();

    return convertToSeconds(now.getTime());
}

function daysToMilliseconds(days) {
    return days * 24 * 60 * 60 * 1000;
}

const abbreviatedDayMap = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
};

const abbreviatedMonthMap = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
};

const monthMap = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
};

const cleanDate = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
};

const cleanDay = (timestamp) => {
    const date = new Date(timestamp * 1000);

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.getTime();
};

const thisWeek = () => {
    const now = new Date();

    const earliestTime = 6;
    const latestTime = 21;

    const earliestDay = 1;
    const latestDay = 5;

    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    now.setHours(earliestTime);

    const nowDay = now.getDay();

    // get a base epoch to calculate start
    let startDate = now.getTime();

    // handle end time now that you've grabbed a base timestamp for the start
    now.setHours(latestTime);

    // get a base epoch with the right end time
    let endDate = now.getTime();

    let offsetStartDays = nowDay - earliestDay;
    let offsetEndDays = latestDay - nowDay;

    // if within the same week but after the latest day
    if (offsetEndDays < 0) {
        offsetStartDays = ((7 - nowDay) + earliestDay) * -1;
        offsetEndDays = (latestDay - earliestDay) + Math.abs(offsetStartDays);
    }

    const offsetStartMilliseconds = daysToMilliseconds(offsetStartDays);
    const offsetEndMilliseconds = daysToMilliseconds(offsetEndDays);

    // roll back to earliest time on earliest day
    startDate -= offsetStartMilliseconds;

    // roll forward to latest time on latest day
    endDate += offsetEndMilliseconds;

    return {
        endDate: convertToSeconds(endDate),
        startDate: convertToSeconds(startDate),
    };
};

const isToday = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();

    if (now.getDate() !== date.getDate()) {
        return false;
    }

    if (now.getMonth() !== date.getMonth()) {
        return false;
    }

    if (now.getFullYear() !== date.getFullYear()) {
        return false;
    }

    return true;
};

const offsetMonth = (offset = 0) => {
    const now = new Date();
    const currentMonth = now.getMonth();
    let newMonth;

    const currentYear = now.getFullYear();

    if ((currentMonth + offset) < 0) {
        now.setFullYear(currentYear - 1);
        newMonth = (currentMonth + 12) + offset;
    } else if (currentMonth + offset > 11) {
        now.setFullYear(currentYear + 1);
        newMonth = (currentMonth + offset) - 12;
    } else {
        newMonth = currentMonth + offset;
    }

    return {
        date: now,
        month: newMonth,
        year: now.getFullYear(),
    };
};

const offsetYear = (offset = 0) => {
    const now = new Date();
    const currentYear = now.getFullYear();
    now.setFullYear(currentYear + offset);

    return {
        date: now,
        year: now.getFullYear(),
    };
};

const thisMonth = (offset) => {
    const { date, month } = offsetMonth(offset);

    date.setDate(1);
    date.setMonth(month);
    const startDate = date.getTime();

    date.setMonth(month + 1);
    date.setDate(1);
    date.setHours(0);
    const endDate = cleanDate(date);

    const endTimestamp = endDate.getTime();
    return {
        startDate: convertToSeconds(startDate),
        endDate: convertToSeconds(endTimestamp),
    };
};

const thisYear = (offset) => {
    const { date, year } = offsetYear(offset);

    date.setDate(1);
    date.setMonth(0);
    date.setHours(0);
    const startDate = date.getTime();

    date.setFullYear(year + 1);
    const endDate = cleanDate(date);

    const endTimestamp = endDate.getTime();

    return {
        startDate: convertToSeconds(startDate),
        endDate: convertToSeconds(endTimestamp),
    };
};

const mixin30mins = {
    methods: {
        in30mins,
    },
};

const leadingZero = (int) => {
    if (int < 10) {
        return `0${int}`;
    }
    return int.toString();
};

const trailingZero = (int) => {
    if (int === 0) {
        return `${int}0`;
    }
    return int.toString();
};

const createTimeValue = (hours, minutes) => `${leadingZero(hours)}:${trailingZero(minutes)}`;

const epochsToTimeAndDate = (startEpoch, endEpoch) => {
    const startDate = new Date(startEpoch * 1000);
    const endDate = new Date(endEpoch * 1000);

    const startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();

    const timeStart = createTimeValue(startHours, startMinutes);

    const endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();

    const timeEnd = createTimeValue(endHours, endMinutes);

    const dayOfWeek = abbreviatedDayMap[startDate.getDay()];
    const month = abbreviatedMonthMap[startDate.getMonth()];

    return {
        dayOfWeek,
        month,
        date: startDate,
        timeStart,
        timeEnd,
    };
};

const dateString = (timestamp) => {
    const date = new Date(timestamp);
    const month = monthMap[date.getMonth()];

    const day = date.getDate();
    const year = date.getFullYear();

    if (isToday(timestamp)) {
        return 'Today';
    }

    return `${month} ${day}, ${year}`;
};

const dateStringSimple = (timestamp) => {
    const date = new Date(timestamp);
    const month = monthMap[date.getMonth()];

    const day = date.getDate();

    return `${month} ${day}`;
};

const secondsEpochFromDate = (date) => Math.floor(date.getTime() / 1000);

const epochFromDateAndTime = (_date, timeslot) => {
    // returns in seconds for API compatibility
    let startDate;
    let endDate;

    // if no times, return full day range
    if (timeslot) {
        const { start, end } = timeSlotsMap[timeslot];
        startDate = cleanDate(_date).setHours(start);
        endDate = cleanDate(_date).setHours(end);

        startDate /= 1000;
        endDate /= 1000;
    } else {
        const cleanStartDate = cleanDate(_date);
        startDate = secondsEpochFromDate(cleanStartDate);
        endDate = secondsEpochFromDate(new Date(cleanStartDate.getTime() + (1000 * 60 * 60 * 24 - 1)));
    }

    return {
        startDate,
        endDate,
    };
};

const getDatesInRange = (range) => {
    const dates = [];

    if (range === 'month') {
        const date = cleanDate(new Date());
        const currentMonth = date.getMonth();

        date.setDate(1);
        while (date.getMonth() === currentMonth) {
            const newDate = new Date(date);
            dates.push(newDate);

            date.setDate(date.getDate() + 1);
        }
    }

    if (range === 'week') {
        const { startDate, endDate } = thisWeek();
        const first = cleanDate(new Date(startDate * 1000));
        const last = cleanDate(new Date(endDate * 1000));

        const date = first;

        while (date.getDate() !== last.getDate()) {
            const newDate = new Date(date);
            dates.push(newDate);

            date.setDate(date.getDate() + 1);
        }
        dates.push(last);
    }

    return dates;
};

export {
    convertToSeconds,
    epochFromDateAndTime,
    getDatesInRange,
    mixin30mins,
    in30mins,
    inXdays,
    timestampNow,
    cleanDate,
    thisWeek,
    thisMonth,
    thisYear,
    epochsToTimeAndDate,
    dateString,
    dateStringSimple,
    cleanDay,
    monthMap,
    offsetMonth,
    leadingZero,
};
