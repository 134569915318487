import assetList from '@/data/assets/org_content.json';
import { baseAssetCategory } from '@/constants';

const env = process.env.NODE_ENV;
// const fallBackLogo = require('@/assets/de-logo_md.png');
// keys used in categories to determine things aside from subcategories
const helperKeys = ['meta', 'prioritize'];

const getMap = () => {
    const orgIdMapDEV = {
        '749e329b-f081-4214-8d74-27886d1fd078': 'discovery-education',
        '0925e46a-224e-48c8-945f-9f3438ace256': 'sanofi',
        '6281301b-6004-4e3f-9abd-79edf93a1573': 'genentech',
        '902ba65b-268b-4063-860b-61b9a5ecbd7c': 'lyda-hill',
    };

    const orgIdMapQA = {
        '29420796-6b13-4d35-b68a-c73dc24a94a7': 'discovery-education',
        '458271fd-2f1a-41c9-b69a-74fcf265f842': 'sanofi',
        '346e96d3-9efd-4e50-8772-fafddab6d7b4': 'genentech',
        '7691ad73-ce0c-4a20-9059-89c227a808fc': 'lyda-hill',
    };

    const orgIdMap = {
        'd26a0ea0-1d34-41ec-8688-84022d808739': 'discovery-education',
        'e9cad66e-7785-4b51-be60-c22cd2c08b41': 'genentech',
        '575c5c47-801a-4e23-84b0-207cc8a8473d': 'sanofi',
        '8254ceb8-ec7d-45fb-8797-65a650e17fdb': 'lyda-hill',
        '09ba95fb-9c3e-4bbe-aee0-c4314a507bd7': 'norton',
        'ff59770f-d8ad-4cd0-bdda-cdbc1df383fa': 'asme',
        '1a986a09-19bb-4e4d-bf8a-0c2ba5b41f79': 'nucor',
    };

    if (env === 'development') {
        return orgIdMapDEV;
    }

    if (env === 'qa') {
        return orgIdMapQA;
    }

    return orgIdMap;
};

const organizations = () => {
    const excludeKeys = ['evergreen'];
    const orgIds = Object.keys(assetList).filter((each) => !excludeKeys.find((key) => each.includes(key)));

    return orgIds;
};

export const getSemanticKey = (orgId) => getMap()[orgId] || orgId;

export const getAssetCategory = (assetId, orgOnly) => {
    let categories = Object.keys(assetList);

    if (orgOnly) {
        categories = categories.filter((category) => !assetList[category].meta);
    }
    // get category from asset id
    const match = categories.find((category) => {
        const isArray = !!assetList[category].length;

        if (!isArray) {
            const subcategories = Object.keys(assetList[category]).filter((key) => !helperKeys.includes(key));
            return subcategories.find((subcategory) => assetList[category][subcategory].assets.find((id) => id === assetId));
        }
        return assetList[category].find((id) => id === assetId);
    });

    return match;
};

export const getAssetContext = (assetId) => {
    // can include meta categories
    const categories = Object.keys(assetList);
    const context = {};

    // get category from asset id
    categories.forEach((category) => {
        const subcategories = Object.keys(assetList[category]).filter((key) => !helperKeys.includes(key));
        const match = subcategories.find((subcategory) => assetList[category][subcategory].assets.find((id) => id === assetId));
        if (match) {
            context[category] = {
                subcategoryDisplay: assetList[category][match].name,
                subcategory: match,
                meta: assetList[category].meta,
            };
        }
    });

    return context;
};

export const getAssetOrgId = (assetId) => {
    const map = getMap();
    const category = getAssetCategory(assetId, true);
    const ids = Object.keys(map);

    const match = ids.find((id) => map[id] === category);

    return match;
};

export const getOrgId = (key) => {
    const map = getMap();
    const ids = Object.keys(map);
    const match = ids.find((id) => map[id] === key);
    if (match) {
        return match;
    }
    return key;
};

// can be an organization "semantic key"
export const spotlightKey = null;

export const bannerOrder = () => {
    let order = [
        'general',
        'dna-day',
        'lyda-hill',
    ];

    if (spotlightKey) {
        const spotlightIdx = order.findIndex((key) => key === spotlightKey);
        if (spotlightIdx < 0) {
            return order;
        }

        order = order.toSpliced(spotlightIdx, 1);
        order.unshift(spotlightKey);
    }

    return order;
};

export const spotlights = {
    'lyda-hill': {
        key: 'lyda-hill',
        assetGroupTitle: 'Spotlight On: Women in Science',
        id: getOrgId('lyda-hill'),
        type: 'organization',
        banner: {
            active: true,
            overline: 'Spotlight on',
            title: 'Women in Science',
            description: 'View this curated collection of dynamic female leaders in science to bring your lessons to life.',
            logoAltText: 'IF/THEN Collection',
            buttons: [
                {
                    display: 'View Now',
                },
            ],
        },
    },
    'dna-day': {
        key: 'dna-day',
        assetGroupTitle: 'Spotlight on DNA',
        id: getOrgId('dna-day'),
        // can be 'content' or 'organization'
        type: 'content',
        banner: {
            active: true,
            overline: 'Spotlight on',
            title: 'DNA',
            description: `Celebrate the workforce behind the building
                blocks of our cell structure and the advancements made through biotechnology`,
            buttons: [
                {
                    display: 'View Now',
                },
            ],
        },
    },
    general: {
        key: 'general',
        assetGroupTitle: 'Connecting Classrooms to Careers',
        // can be 'content', 'organization', or 'general'
        type: 'general',
        banner: {
            active: true,
            title: 'Connecting Classrooms to Careers',
            description: `Bring career exploration and real-world applications to your students by connecting
            them directly with experts across fields and industries`,
            buttons: [
                {
                    display: 'View Now',
                },
            ],
        },
    },
};

export const spotlight = spotlights[spotlightKey] || null;

export const getRelatedAssets = (assetId) => {
    // to be used when viewing an individual asset
    const category = getAssetCategory(assetId);
    const isArray = Array.isArray(assetList[category]);
    let related;

    if (!isArray) {
        const subcategories = Object.keys(assetList[category]).filter((subcategory) => !helperKeys.includes(subcategory));
        const subcategory = subcategories.find((each) => assetList[category][each].assets.find((id) => id === assetId));

        related = assetList[category][subcategory].assets;
    } else {
        related = assetList[category];
    }

    return related.sort(() => 0.5 - Math.random()).filter((id) => id !== assetId);
};

// eslint-disable-next-line arrow-body-style
const removeDuplicates = (arr) => {
    return arr.filter((item, idx) => arr.indexOf(item) === idx);
};

export const resourceList = (topic, subcategory) => removeDuplicates(assetList[topic][subcategory].assets);

const getNestedAssets = (orgKey) => {
    let list = [];
    const subcategories = Object.keys(assetList[orgKey]);
    subcategories.forEach((subcategory) => {
        list = list.concat(assetList[orgKey][subcategory].assets);
    });

    return list;
};

// returns a flattened list of all assets within all subcategories
export const singleOrgContent = (key) => {
    if (!assetList[key]) {
        return [];
    }
    const list = Array.isArray(assetList[key]) ? assetList[key] : getNestedAssets(key);
    return removeDuplicates(list);
};

export const getSubcategoryContent = (key, subcategory) => removeDuplicates(assetList[key][subcategory].assets);

export const orgSubCategories = (orgId) => {
    const key = getSemanticKey(orgId);

    return Object.keys(assetList[key]);
};

export const orgContentViewAllPath = (orgId) => {
    const key = getSemanticKey(orgId);

    if (key === baseAssetCategory) {
        return `/organization/${orgId}/content`;
    }

    return `/organization/${orgId}/${key}/content`;
};

export const getOrgContentArray = (orgId) => {
    const toReturn = [];
    const key = getSemanticKey(orgId);

    const orgResources = assetList[key];

    if (!orgResources) {
        return [];
    }
    const subcategories = Object.keys(orgResources);

    subcategories.forEach((subcategory) => {
        if (helperKeys.includes(subcategory)) {
            return;
        }
        toReturn.push({
            ...orgResources[subcategory],
            ...{ key: subcategory },
        });
    });
    return removeDuplicates(toReturn);
};

export const allOrgContent = (currentSpotlightKey) => {
    let list = [];

    organizations().forEach((each) => {
        if (currentSpotlightKey && currentSpotlightKey === each) {
            return;
        }
        const sourceList = singleOrgContent(each);
        list = list.concat(sourceList);
    });

    return list;
};

export const randomOrder = (arr, category) => {
    let randomized = arr.sort(() => 0.5 - Math.random());

    // handles the option for some single-category rows to always put a certain asset(s) first
    if (category && assetList[category]) {
        const { prioritize } = assetList[category];
        if (!prioritize) {
            return randomized;
        }
        const first = [];
        // if there are some that need to be prioritized, remove those and store to be placed first
        const cleanArr = arr.filter((guid) => {
            if (prioritize.includes(guid)) {
                first.push(guid);
                return false;
            }
            return true;
        });
        randomized = cleanArr.sort(() => 0.5 - Math.random());
        randomized.unshift(prioritize);
    }

    return randomized;
};

export const order = (arr) => arr.sort();

// to handle when a specific organization's content should be highlighted
// but some randomization still wanted to highlight different assets within the highlighted org
export const assetRandomOrder = (arr, currentSpotlight) => {
    let prioritizedAssets = [];
    if (currentSpotlight) {
        const highlightedNumber = 4;
        const spotlightContent = singleOrgContent(currentSpotlight.key);
        prioritizedAssets = randomOrder(spotlightContent).slice(0, highlightedNumber);
    }

    const ordered = randomOrder(arr);

    return prioritizedAssets.concat(ordered);
};

// to handle when a specific organization's content should be highlighted
// but consistent ordering is needed
export const assetOrder = (arr) => {
    const prioritizedAssets = [];

    const ordered = order(arr);

    return prioritizedAssets.concat(ordered);
};

export const randomizeAndTruncate = (list, assetId, max = 4) => list.sort(() => 0.5 - Math.random())
    .filter((id) => id !== assetId)
    .slice(0, max);
