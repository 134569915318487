import getToken from '@/utils/getToken';
import { partnerRoles } from '@/constants';

export default {
    methods: {
        async createPayload(data) {
            const { mode: appMode } = this.$store.state.app;
            let token;
            if (partnerRoles.includes(appMode)) {
                token = await getToken(this.$auth);
                return {
                    data,
                    token,
                };
            }
            token = await this.$de.getTokenSilently();
            return {
                data,
                token,
            };
        },
    },
};
