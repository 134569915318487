const timeSlotsMap = {
    'early-morning': {
        start: 6,
        end: 8,
    },
    morning: {
        start: 8,
        end: 10,
    },
    'late-morning': {
        start: 10,
        end: 12,
    },
    afternoon: {
        start: 12,
        end: 14,
    },
    'late-afternoon': {
        start: 14,
        end: 17,
    },
    evening: {
        start: 17,
        end: 20,
    },
};

export default timeSlotsMap;
