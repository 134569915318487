<template>
    <component
        :is="avatarElement"
        :class="avatarClassName"
        ref="avatar"
        v-bind="isLink"
        :style="cssProps"
    >
        <img v-if="image" class="profile-avatar__image" :src="image" :alt="name">

        <NebulaIcon
            v-else
            :symbolId="icon"
            class="profile-avatar__icon"
        />
    </component>
</template>

<script>
import { NebulaIcon, NebulaThemingUtils } from '@discoveryedu/nebula-components';

export default {
    name: 'ProfileAvatar',
    components: {
        NebulaIcon,
    },
    mixins: [NebulaThemingUtils],
    data() {
        return {
            themePrefix: 'careers',
        };
    },
    props: {
        image: {
            type: String,
            default: '',
        },
        logo: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: 'user--filled',
        },
        border: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        link: {
            type: String,
            default: '',
        },
        /**
         * The size of the button. xs, s, m, l, or xl
         */
        size: {
            type: String,
            default: 'm',
            enum: ['xs', 's', 'm', 'l', 'xl'],
        },
    },
    computed: {
        avatarClassName() {
            const className = [
                'profile-avatar',
                `profile-avatar--${this.size}`,
                {
                    'profile-avatar--border': this.border,
                    'profile-avatar--link': this.link,
                    'profile-avatar--logo': this.logo,
                },
            ];
            return className;
        },
        avatarElement() {
            let avatarElement = 'div';

            if (this.link) {
                avatarElement = 'a';
            }

            return avatarElement;
        },
        isLink() {
            if (this.link) {
                return { href: this.link };
            }
            return false;
        },
    },
};
</script>

<style lang='stylus'>
:root {
    --careers-avatar-background-color: var(--nebula-color-platform-interface-300);
    --careers-avatar-border-color: var(--nebula-color-platform-interface-200);
    --careers-avatar-icon-color: var(--nebula-color-platform-interface-800);
    --careers-avatar-icon-size: 32px;
    --careers-avatar-size: 64px;
}

.profile-avatar {
    align-items: center;
    background-color: var(--careers-avatar-background-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: var(--careers-avatar-size);
    overflow: hidden;
    text-decoration: none;
    width: var(--careers-avatar-size);

    &--xs {
        --careers-avatar-size: 32px;
        --careers-avatar-icon-size: 16px;
    }

    &--s {
        --careers-avatar-size: 40px;
        --careers-avatar-icon-size: 24px;
    }

    &--l {
        --careers-avatar-size: 100px;
        --careers-avatar-icon-size: 64px;
    }

    &--xl {
        --careers-avatar-size: 136px;
        --nebula-icon-size-m: 80px;
    }

    &--border {
        border: 3px solid var(--careers-avatar-border-color);
    }

    &__image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__icon {
        fill: var(--careers-avatar-icon-color);
        height: var(--careers-avatar-icon-size);
        width: var(--careers-avatar-icon-size);
    }

    &--logo {
        .profile-avatar__image {
            object-fit: contain;
            width: 75%;
        }
    }
}
</style>
