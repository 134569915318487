<template>
    <NebulaGridRow gutter v-if="cardRow">
        <NebulaGridBlock :class="className" :columns="3" :mdSize="4" :smSize="4" v-for="n in 4" :key="n" />
    </NebulaGridRow>
    <div v-else :class="className" />
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';

export default {
    name: 'GrayBoxLoader',
    components: {
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
    },
    props: {
        card: Boolean,
        cardRow: Boolean,
        rounded: Boolean,
    },
    computed: {
        className() {
            const classes = ['gray-box-loader'];

            if (this.card || this.cardRow) {
                classes.push('gray-box-loader--card');
            }

            if (this.rounded) {
                classes.push('gray-box-loader--rounded');
            }

            return classes;
        },
    },
};
</script>

<style lang="stylus">
@keyframes gradient {
    0%{
        background-position: 200%
    }
    100%{
        border: none;
        background-position: 0%
    }
}

.gray-box-loader {
    animation: gradient 2s infinite;
    animation-timing-function: linear;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        $nebula-color-platform-neutral-100 0%,
        $nebula-color-platform-neutral-200 30%,
        $nebula-color-platform-neutral-200 60%,
        $nebula-color-platform-neutral-100 100%
    );
    background-size: 200%;
    &--rounded {
        border-radius: $nebula-border-radius-small;
    }
    &--card {
        border-radius: $nebula-border-radius-small;
        min-height: 300px;
        height: 100%;
    }
}
</style>
