<template>
    <a
        class="careers-global-nav__button"
        data-click-type="Global Navigation"
        :data-click-name="name"
    >
        <slot />
    </a>
</template>

<script>

export default {
    name: 'GlobalNavButton',
    props: {
        name: {
            type: String,
            default: 'Global Nav Button',
        },
    },
};

</script>

<style lang="stylus">
    :root {
        --careers-global-nav-button-border: none;
        --careers-global-nav-button-border-radius: $nebula-border-radius-button-default;
        --careers-global-nav-button-padding: $nebula-space-half $nebula-space-2x;
        --careers-global-nav-button-background: transparent;
        --careers-global-nav-button-background-hover: $nebula-color-platform-interactive-200;
    }
    .careers-global-nav__button {
        background: var(--careers-global-nav-button-background);
        border: var(--careers-global-nav-button-border);
        border-radius: var(--careers-global-nav-button-border-radius);
        padding: var(--careers-global-nav-button-padding);
        text-decoration: none;
        transition: $nebula-transition-default;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        &:hover,
        &:focus-visible {
            background: var(--careers-global-nav-button-background-hover);
        }
    }
</style>
