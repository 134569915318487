import axios from 'axios';

const API_DOMAIN = process.env.VUE_APP_API_URL;

/* eslint-disable import/prefer-default-export */
export const fetchAssetFromDE = async (token, id) => {
    const response = await axios.get(`${API_DOMAIN}/deproxy/v1/assets/${id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    return response;
};

export const createURLParams = (obj) => {
    const keys = Object.keys(obj);

    const toConvert = keys.map((each) => [each, obj[each]]);

    return new URLSearchParams(toConvert).toString();
};

export const reqHeaders = (payload, contentType = 'json', custom = {}) => {
    const { token } = payload;
    const contentTypes = {
        json: 'application/json',
    };

    const baseHeaders = {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentTypes[contentType],
    };

    return {
        headers: {
            ...baseHeaders,
            ...custom,
        },
    };
};

/* eslint-disable import/prefer-default-export */
export const get = async (payload, endpoint, params) => {
    const paramString = params ? `?${createURLParams(params)}` : '';
    const { token } = payload;
    const response = await axios.get(`${API_DOMAIN}${endpoint}${paramString}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    return response;
};

/* eslint-disable import/prefer-default-export */
export const post = async (payload, endpoint, params) => {
    const paramString = params ? `?${createURLParams(params)}` : '';
    const { token } = payload;
    const response = await axios.post(`${API_DOMAIN}${endpoint}${paramString}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    return response;
};
