export default {
    methods: {
        getWeekDay(date) {
            const weekday = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ];

            return weekday[date.getDay()];
        },
        formatDate(epochTimestamp, format) {
            const date = new Date(epochTimestamp * 1000);

            if (format === 'weekday') {
                // Format: Weekday, Month Day(suffix)
                const options = {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                };
                const formattedDate = date.toLocaleDateString('en-US', options);
                const day = date.getDate();

                let suffix;
                if (day === 1 || day === 21 || day === 31) {
                    suffix = 'st';
                } else if (day === 2 || day === 22) {
                    suffix = 'nd';
                } else if (day === 3 || day === 23) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return formattedDate.replace(/(\d+)/, `$1${suffix}`);
            }

            if (format === 'stacked') {
                const options = {
                    weekday: 'short',
                    month: 'short',
                    day: '2-digit',
                };
                const formattedDate = date.toLocaleDateString('en-US', options);
                return formattedDate.replace(',', '');
            }

            // Format: XX/XX/XXXX
            const options = {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            };
            return date.toLocaleDateString('en-US', options);
        },
    },
};
