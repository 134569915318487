const getTimezoneAbbreviation = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneName = new Intl.DateTimeFormat(
        'en', { timeZone: timezone, timeZoneName: 'long' },
    ).formatToParts().find((each) => each.type === 'timeZoneName').value;
    const firstLetters = timezoneName.match(/\b(\w)/g);
    return firstLetters.join('').toUpperCase();
};

function formatTime(timestamp, _hours, _minutes, timezone = true) {
    let hours = _hours;
    let minutes = _minutes;
    let timezoneString = '';

    if (timestamp) {
        const date = new Date(timestamp);
        hours = date.getHours();
        minutes = date.getMinutes();
    }

    let hourString = hours % 12;
    let suffix = 'AM';

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }

    // set to PM if after noon
    if (hours >= 12) {
        suffix = 'PM';
    }

    // Handle midnight just to be clean here
    if (hourString === 0) {
        hourString = '12';
    }

    if (timezone) {
        timezoneString = ` ${getTimezoneAbbreviation()}`;
    }

    return `${hourString}:${minutes} ${suffix}${timezoneString}`;
}

export default formatTime;
