import { leadingZero } from '@/data/placeholder/date';

const implementPendo = (instance) => {
    const { pendo } = window;
    let authenticated = false;
    let deAuth;
    let envString = '';
    const env = process.env.NODE_ENV;
    let { userId } = instance.$store.getters;

    // get login date string
    const date = new Date();
    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth();
    const careerConnectLastSignIn = `${year}-${leadingZero(month + 1)}-${leadingZero(day)}`;

    // capture some extra metadata
    const { locale: localeCombined } = Intl.DateTimeFormat().resolvedOptions();
    const regex = new RegExp('^(\\w{2})(?:-)(\\w{2})$');
    const match = regex.exec(localeCombined);
    const locale = match[1];
    const country = match[2];

    const envMap = {
        development: 'DEV',
        qa: 'QA',
        staging: 'STAGE',
        production: 'PROD',
    };

    const { isAuthenticated: partnerAuthenticated } = instance.$auth;

    if (partnerAuthenticated) {
        authenticated = true;
    } else if (instance.$de.isAuthenticated) {
        authenticated = true;
        deAuth = true;
    }

    if (authenticated) {
        let accountData = {};
        let siteData = {};
        const authenticationType = deAuth ? 'oauth2.discoveryed' : 'auth0';

        if (deAuth) {
            const {
                account_name: accountName,
                account_guid: accountId,
                site_name: siteName,
                site_guid: siteId,
            } = instance.$store.getters.userData.site;

            accountData = {
                ...accountData,
                ...{
                    id: accountId,
                    name: accountName,
                },
            };

            siteData = {
                ...siteData,
                ...{
                    id: siteId,
                    name: siteName,
                },
            };
        } else {
            userId = `careerConnect-${userId}`;
            accountData = {
                ...accountData,
                ...{
                    id: `careerConnect-${instance.$store.getters.organizationId}`,
                    name: instance.$store.getters.getOrganization.name,
                },
            };
        }

        if (env !== 'production') {
            envString = `-${envMap[env]}`;
        }

        pendo.initialize({
            visitor: {
                id: `${userId}${envString}`,
                careerConnectRole: instance.$store.getters.appMode,
                environment: envMap[env],
                authenticationType,
                locale,
                country,
                userAgent: window.navigator.userAgent,
                screenHeight: window.screen.height,
                screenWidth: window.screen.width,
                careerConnectLastSignIn,
            },
            account: siteData,
            parentAccount: accountData,
        });
    }
};

export default implementPendo;
