const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    },
    {
        path: '/profile-setup',
        name: 'profile-setup',
        component: () => import(/* webpackChunkName: "profile-setup" */ '../views/ProfileSetupNew.vue'),
    },
    {
        path: '/organization/:id',
        name: 'organization-profile',
        component: () => import(/* webpackChunkName: "organization-profile" */ '../views/EmployerProfilePublic.vue'),
    },
    {
        path: '/organization/:id/content',
        name: 'organization-content',
        component: () => import(/* webpackChunkName: "organization-profile" */ '../views/browse/BrowseContent.vue'),
    },
    {
        path: '/organization/:id/:subcategory/content',
        name: 'organization-category-content',
        component: () => import(/* webpackChunkName: "organization-profile" */ '../views/browse/BrowseContent.vue'),
    },
    {
        path: '/connector/:id',
        name: 'connector-profile',
        component: () => import(/* webpackChunkName: "connector-profile" */ '../views/EmployeeProfilePublic.vue'),
    },
    {
        path: '/asset/:id',
        name: 'asset-details',
        component: () => import(/* webpackChunkName: "asset" */ '../views/AssetDetails.vue'),
    },
    {
        path: '/user-management',
        name: 'user-management',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "employer-user-management" */ '../views/EmployerUserManagement.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    },
    {
        path: '/oauth2/authorized',
        name: 'oauth2-authorized',
        component: () => import(/* webpackChunkName: "authorized" */ '../views/AuthorizedRoute.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginSelect.vue'),
    },
    {
        path: '/connectors',
        name: 'browse-connectors',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/browse/BrowseConnectors.vue'),
    },
    {
        path: '/:org_id/connectors',
        name: 'browse-org-connectors',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/browse/BrowseConnectors.vue'),
    },
    {
        path: '/organizations',
        name: 'browse-organizations',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/browse/BrowseOrganizations.vue'),
    },
    {
        path: '/request/new',
        name: 'new-request',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/requests/NewRequest.vue'),
    },
    {
        path: '/connect-directory',
        name: 'connect-directory',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/requests/RequestDirectory.vue'),
    },
    {
        path: '/request/:id',
        name: 'request-details',
        meta: {
            segmentName: 'Request Details',
        },
        component: () => import(/* webpackChunkName: "connectors" */ '../views/requests/RequestDetails.vue'),
    },
    {
        path: '/request/:id/edit',
        name: 'request-edit',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/requests/RequestEdit.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    },
    {
        path: '/browse',
        name: 'browse-all',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/browse/BrowseAll.vue'),
    },
    {
        path: '/content',
        name: 'browse-content',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/browse/BrowseContent.vue'),
    },
    {
        path: '/direct-request/:id',
        name: 'direct-request',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/educators/DirectRequest.vue'),
    },
    {
        path: '/content/:spotlight_key',
        name: 'spotlight-content',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/browse/SpotlightContent.vue'),
    },
    {
        path: '/message',
        name: 'message',
        component: () => import(/* webpackChunkName: "connectors" */ '../views/Message.vue'),
    },
    // DE ADMIN VIEWS
    {
        path: '/org-management',
        name: 'org-management',
        component: () => import(/* webpackChunkName: "login" */ '../views/admin/OrgManagement.vue'),
    },
    {
        path: '/educator-management',
        name: 'educator-management',
        component: () => import(/* webpackChunkName: "login" */ '../views/admin/EducatorManagement.vue'),
    },
    {
        path: '/request-admin',
        name: 'request-admin',
        component: () => import(/* webpackChunkName: "login" */ '../views/admin/Request.vue'),
    },
    {
        path: '/test-asset',
        name: 'test-asset',
        component: () => import(/* webpackChunkName: "login" */ '../views/admin/AssetTest.vue'),
    },
    {
        path: '/feature-flags',
        name: 'feature-flags',
        component: () => import(/* webpackChunkName: "login" */ '../views/admin/FeatureFlags.vue'),
    },
];

export default routes;
