import { typeOptions } from '@/data/formData';

const displayName = (data) => {
    const { preferredName, firstName, lastName } = data;
    return `${preferredName || firstName} ${lastName}`;
};

const requestTypeDisplay = (type) => {
    if (!type) {
        return '';
    }

    const typeObj = typeOptions.find((each) => each.value === type);
    return typeObj.text;
};

export {
    displayName,
    requestTypeDisplay,
};
