const pageSizes = [
    {
        value: '12',
        label: '12',
    },
    {
        value: '24',
        label: '24',
    },
];

export default { pageSizes };
