import axios from 'axios';
import { reqHeaders } from '@/utils/fetches';

import {
    timestampNow,
    dateString,
    cleanDay,
} from '@/data/placeholder/date';

const API_DOMAIN = process.env.VUE_APP_API_URL;

const getAblyTokenRequest = async (context, payload) => {
    let token;

    const headers = reqHeaders(payload);

    try {
        const response = await axios.get(`${API_DOMAIN}/messaging/token_request`, headers);
        token = response.data;
    } catch (error) {
        console.log(error);
    }

    return token;
};

const getMessages = async ({ commit, getters }, payload) => {
    const { requestId } = payload.data;
    const { headers } = reqHeaders(payload);

    try {
        const response = await axios.get(`${API_DOMAIN}/messaging/${requestId}`, { headers });
        let message = response.data && response.data.length > 0 ? JSON.parse(response.data) : [];

        const currentUserId = getters.userId;
        const parsed = {};

        message = message.filter((each) => !!each.timeStamp)
            .sort((a, b) => {
                const aTime = parseInt(a.timeStamp, 10);
                const bTime = parseInt(b.timeStamp, 10);
                let result = 0;
                if (aTime < bTime) {
                    result = -1;
                } else if (aTime > bTime) {
                    result = 1;
                }

                return result;
            });

        message.forEach((each) => {
            let self = false;
            let { timestamp } = each;

            const daysStored = Object.keys(parsed);

            if (each.userId === currentUserId) {
                self = true;
            }

            if (!timestamp && each.timeStamp) {
                timestamp = each.timeStamp;
            }

            if (!timestamp) {
                // bail if timestamp not truthy
                return;
            }

            const day = cleanDay(timestamp);

            const messageItem = {
                ...each,
                timestamp,
                self,
            };

            if (daysStored.includes(day.toString())) {
                parsed[day].messages.push(messageItem);
                return;
            }

            parsed[day] = {
                display: dateString(day),
                messages: [messageItem],
            };
        });

        commit('UPDATE_PAGE', { message: parsed });
    } catch (error) {
        console.log(error);
    }
};

const sendMessage = async ({ commit }, payload) => {
    const { requestId, message } = payload.data;
    const { headers } = reqHeaders(payload);

    const timestamp = timestampNow();

    const body = {
        timeStamp: timestamp,
        message,
    };

    try {
        const response = await axios.post(
            `${API_DOMAIN}/messaging/${requestId}`,
            body,
            { headers },
        );

        commit('UPDATE_PAGE', response.data);
    } catch (error) {
        console.log(error);
    }
};

const createMessageStore = async (context, payload) => {
    console.log('createMessageStore');
    const { headers } = reqHeaders(payload);
    const { data } = payload;

    try {
        await axios.post(`${API_DOMAIN}/messaging`, data, { headers });
    } catch (error) {
        console.log(error);
    }
};

const getToken = async ({ commit }, payload) => {
    const { tokenRequest } = payload;

    let token;

    try {
        const response = await axios.post(`https://rest.ably.io/keys/${tokenRequest.keyName}/requestToken`,
            tokenRequest,
            {});
        token = response.data.token;
    } catch (error) {
        commit('UPDATE_PAGE', { error: { description: 'Something went wrong, please try again later' } });
    }

    return token;
};

export default {
    getAblyTokenRequest,
    getMessages,
    createMessageStore,
    getToken,
    sendMessage,
};
