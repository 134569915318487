import { get, post } from '@/utils/fetches';
import { roles, notifications } from '@/constants';

// handle when one setting covers multiple notification types
const getSetting = (data, destination, type) => {
    const { notificationSettingsType } = notifications;
    const includedTypes = (notificationSettingsType[type].notification_types || [type]).filter((each) => Object.keys(data).includes(each));
    const settings = includedTypes.filter((each) => !!data[each]).map((each) => data[each][destination]);
    return settings.includes(true);
};

const parseNotifications = (data, appMode) => {
    const env = process.env.NODE_ENV;

    const preprodOnly = ['upcoming_connect_1_hour', 'upcoming_connect_3_hours'];
    const preprodEnvs = ['development', 'qa'];

    // order is set separately from the mapping above because it's slightly different for the different roles
    // also the roles share a couple notification types
    const notificationSettingsOrder = {
        [roles.EMPLOYEE_ROLE]: [
            'new_message',
            'upcoming_connect',
            'connect_cancel',
            'request_cancel',
            'new_direct_request',
            'application_sent',
            'application_update',
            'new_scheduled_request',
            'reschedule_request',
            'application_declined',
        ],
        [roles.EDUCATOR_ROLE]: [
            'new_message',
            'confirm_employee',
            'upcoming_connect',
            'connect_cancel',
            'new_direct_request',
            'direct_request_sent',
            'application_declined',
            'application_update',
            'new_scheduled_request',
            'reschedule_request_confirmed',
            'rescheduled_request_declined',
        ],
    };

    const parsed = {};

    let notificationTypes = Object.keys(data);

    if (!preprodEnvs.includes(env)) {
        notificationTypes = notificationTypes.filter((each) => !preprodOnly.includes(each));
    }

    const destinations = Object.keys(data[notificationTypes[0]]);
    const { notificationSettingsType } = notifications;

    destinations.forEach((destination) => {
        parsed[destination] = notificationSettingsOrder[appMode]
            .filter((type) => {
                if (!notificationSettingsType[type]) {
                    return false;
                }
                if (notificationSettingsType[type].notification_types) {
                    const match = notificationSettingsType[type].notification_types.find((each) => notificationTypes.includes(each));
                    return !!match;
                }
                return notificationTypes.includes(type);
            })
            .map((type) => {
                const obj = notificationSettingsType[type];

                const includedTypes = (obj.notification_types || [type]).filter((each) => notificationTypes.includes(each));
                const display = obj.display[appMode];
                const setting = getSetting(data, destination, type);
                return {
                    type: includedTypes,
                    display,
                    setting,
                };
            });
    });
    return parsed;
};

const getNotificationSettings = async ({ dispatch, state }, payload) => {
    try {
        const response = await get(payload, '/settings', { type: 'notification' });
        const parsed = parseNotifications(response.data, state.app.mode);
        dispatch('updatePage', { settings: parsed });
    } catch (e) {
        dispatch('updatePage', { error: { description: 'We were unable to fetch your settings' } });
    }
};

const toggleNotificationSetting = async ({ dispatch, state }, payload) => {
    let response;
    const { data } = payload;

    const funcs = data.type.map((type) => {
        const params = {
            on: (!data.setting).toString(),
            notification_type: type,
            setting_type: 'notification',
            destination: data.destination,
        };

        return post(payload, '/settings', params);
    });
    await Promise.all(funcs)
        .then((responses) => {
            if (responses.length > 1) {
                // with more than one response, there is no guarantee the last response is the final recieved
                // so fetch settings after all are complete
                dispatch('getNotificationSettings', payload);
            } else {
                const parsed = parseNotifications(responses[0].data, state.app.mode);
                dispatch('updatePage', { settings: parsed });
            }
        })
        .catch(() => {
            dispatch('updatePage', { error: { description: 'We were unable to update your settings' } });
        });

    return response;
};

export default {
    getNotificationSettings,
    toggleNotificationSetting,
};
