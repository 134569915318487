<template>
    <NebulaCard
        element="li"
        class="nebula-card-carousel__list-item notification-card"
        @click="viewDetails"
    >
        <template v-slot:content>
            <NebulaIcon
                class="notification-card__icon"
                :class="unread && 'notification-card__icon--unread'"
                symbolId="circle--filled"
                size="s"
            />
            <div>
                <div class="notification-card__header">
                    <h4 class="notification-card__title">{{ title }}</h4>
                </div>
                <NebulaCardDescription
                    class="notification-card__description"
                    :text="description"
                />
                <NebulaCardDescription
                    v-if="message"
                >
                    <span class="notification-card__description-header">Message: </span>{{ message }}
                </NebulaCardDescription>
                <div class="notification-card__footer">
                    <p class="notification-card__footer-timestamp">
                        {{ timeFrame }}
                    </p>
                    <p class="notification-card__footer-link">
                    {{ $t('view-request-details', { ns: 'notifications' }) }}
                    </p>
                </div>
            </div>
        </template>
    </NebulaCard>
</template>

<script>
import { NebulaCard, NebulaCardDescription, NebulaIcon } from '@discoveryedu/nebula-components';
import formatDate from '@/mixins/formatDate';
import breakpoints from '@/mixins/breakpoints';
import timeFromNow from '@/utils/timeRemaining';
import createPayload from '@/mixins/data/createPayload';

import notificationDescriptions from '@/data/content/notificationDescriptions';
import { notifications, roles } from '@/constants';

export default {
    name: 'ResourceCard',
    components: {
        NebulaCard,
        NebulaIcon,
        NebulaCardDescription,
    },
    props: {
        asset: Object,
        assetType: String,
    },
    mixins: [breakpoints, formatDate, createPayload],
    computed: {
        unread() {
            return !this.asset.read;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        partnerString() {
            // only educators and employees get notifications
            const partnerMap = {
                [roles.EDUCATOR_ROLE]: roles.EMPLOYEE_ROLE,
                [roles.EMPLOYEE_ROLE]: roles.EDUCATOR_ROLE,
            };
            return partnerMap[this.appMode];
        },
        timeFrame() {
            return timeFromNow(this.asset.createdDate);
        },
        notificationCategory() {
            const { requestNotifications, messageNotifications } = notifications;
            const { title: notificationType } = this.asset;

            if (messageNotifications.includes(notificationType)) {
                return 'message';
            }

            if (requestNotifications.includes(notificationType)) {
                return 'request';
            }

            return null;
        },
        url() {
            let url;

            if (this.notificationCategory === 'request') {
                url = `/request/${this.asset.description.request.request_id}`;
            } else if (this.notificationCategory === 'message') {
                url = `/message?id=${this.asset.description.request.request_id}`;
            }

            return url;
        },
        linkable() {
            return this.url !== window.location.pathname;
        },
        notificationType() {
            return this.asset.title;
        },
        title() {
            const { notificationDisplayMap } = notifications;
            const supported = Object.keys(notificationDisplayMap);

            if (supported.includes(this.notificationType)) {
                return notificationDisplayMap[this.notificationType];
            }
            return this.notificationType;
        },
        description() {
            if (this.notificationCategory === 'message') {
                const partnerData = this.asset.description[this.partnerString];
                let { first_name: firstName, last_name: lastName } = partnerData;

                // TODO: remove this once we're far enough out from back data in prod, around July
                if (!firstName || !lastName) {
                    firstName = partnerData.preferredName || partnerData.firstName;
                    lastName = partnerData.lastName;
                }
                if (this.asset.title === 'new_message') {
                    return `${firstName} ${lastName} sent you a message!`;
                }
            }
            return notificationDescriptions(this.notificationType, this.asset.description, this.appMode, this.partnerString);
        },
        message() {
            let cancelReason;
            if (!notifications.hasCancelMessages.includes(this.notificationType)) {
                return cancelReason;
            }
            return this.asset.description.request.cancelReason || this.asset.description.request.cancel_reason;
        },
    },
    methods: {
        async viewDetails() {
            // Mark notification as read
            const { read } = this.asset;

            if (!read) {
                const notification = {
                    ...this.asset,
                    ...{ read: true },
                };
                const payload = await this.createPayload(notification);
                await this.$store.dispatch('updateNotification', payload);
            }

            // Navigate to url for notification
            // Skips if already on correct page
            if (this.linkable) {
                this.$store.dispatch('resetPageData');
                this.$router.push(this.url);
            }

            this.$emit('close-notifications');
        },
    },
};
</script>

<style lang='stylus'>
.notification-card {
    --nebula-badge--color: $nebula-color-platform-interactive-900;
    --nebula-badge-background-color: $nebula-color-platform-interactive-200;
    --nebula-card-background-color: $nebula-color-white;
    --nebula-card-border-color: unset;
    --nebula-card-border-radius: $nebula-border-radius-small;
    --nebula-card-border-width: 0;
    --nebula-card-box-shadow: none;
    --nebula-card-content-padding: $nebula-space-1x $nebula-space-2x;
    --nebula-card-header-min-height: 0;
    --nebula-card-margin: 0 0 8px;
    --nebula-card-max-width: 100%;
    --nebula-card-min-width: 100%;
    --nebula-card-thumbnail-border-radius-top: $nebula-border-radius-small;
    --nebula-card-title-margin: 0;
    --nebula-card-type-color: $nebula-color-platform-neutral-900;
    --nebula-font-size-body-2: $nebula-font-size-caption;
    --nebula-font-size-card-title: $nebula-font-size-body-2;
    --nebula-text-color-header-default: $nebula-color-platform-neutral-800;
    --notification-icon-size: 10px;

    cursor: pointer;

    &:focus,
    &:hover {
        background-color: $nebula-color-platform-interactive-100;
    }

    .nebula-card__content {
        display: grid;
        grid-gap: $nebula-space-1x;
        grid-template-columns: var(--notification-icon-size) 1fr;
        grid-template-rows: auto;
    }

    &__metadata {
        display: flex;
        gap: $nebula-space-1x;
        margin-block-start: $nebula-space-1x;
    }

    &__header {
        align-items: center;
        display: flex;
        gap: $nebula-space-1x;
    }

    &__title {
        margin: 0;
        padding: 0;
    }

    &__icon {
        --nebula-icon-size-s: var(--notification-icon-size);
        fill: transparent;
        flex-shrink: 0;
        margin-top: 6px;

        &--unread {
            fill: $nebula-color-platform-interactive-700;
        }
    }

    &__footer {
        align-items: center;
        display: flex;
        gap: $nebula-space-3x;
        justify-content: space-between;
        margin-block-start: $nebula-space-1x;

        &-timestamp {
            font-size: $nebula-font-size-caption;
            font-weight: 700;
            margin: 0;
            padding: 0;
        }

    }

    &__description {
        &.nebula-card__description {
            -webkit-line-clamp: 7;
        }
    }

    &__description-header {
        font-weight: 600;
    }

    &__footer-link {
        color: $nebula-color-platform-interactive-900;
        font-size: $nebula-font-size-caption;
        margin: 0;
    }
}
</style>
