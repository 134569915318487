import axios from 'axios';
// import { getCookie } from '@/utils/cookies';
import Vue from 'vue';

let instance;
// const CODE_PATH = '/oauth2/authorized';

const deAuth = (options) => {
    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                error: null,
            };
        },
        methods: {
            getParams() {
                const { search } = window.location;
                const parsed = new URLSearchParams(search);
                const code = parsed.get('code');
                const state = parsed.get('state');

                return { code, state };
            },
            async refreshToken(token) {
                const clientId = process.env.VUE_APP_DE_CLIENT_ID;
                const API_DOMAIN = process.env.VUE_APP_API_URL;

                const reqParams = new URLSearchParams([
                    ['client_id', clientId],
                    ['grant_type', 'refresh_token'],
                    ['refresh_token', token],
                ]);

                let response;

                try {
                    response = await axios.post(`${API_DOMAIN}/deproxy/oauth2/token`,
                        reqParams,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                        });
                } catch {
                    this.logout();
                }

                const accessToken = this.storeTokens(response.data);
                return accessToken;
            },
            getTokenKey(isRefresh) {
                const clientId = process.env.VUE_APP_DE_CLIENT_ID;

                return `DE::${clientId}::${isRefresh ? 'refresh' : ''}`;
            },
            async requestToken(reqParams) {
                const API_DOMAIN = process.env.VUE_APP_API_URL;
                let response;

                try {
                    response = await axios.post(`${API_DOMAIN}/deproxy/oauth2/token`,
                        reqParams,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                        });
                    this.error = null;
                } catch {
                    this.error = 'Unable to retrieve access token, please try again later';
                    return;
                }

                const accessToken = this.storeTokens(response.data);
                // eslint-disable-next-line consistent-return
                return accessToken;
            },
            storeTokens(data) {
                const { access_token: accessToken, refresh_token: refreshToken, expires_in: expiresIn } = data;

                const now = new Date();
                const expiry = now.getTime() + expiresIn * 1000;
                const body = JSON.stringify({
                    access_token: accessToken,
                    expiry,
                });

                const refreshBody = JSON.stringify({
                    refresh_token: refreshToken,
                });

                const tokenKey = this.getTokenKey();
                const refreshTokenKey = this.getTokenKey(true);

                // store tokens
                localStorage.setItem(tokenKey, body);
                localStorage.setItem(refreshTokenKey, refreshBody);
                this.isAuthenticated = true;

                // eslint-disable-next-line consistent-return
                return accessToken;
            },
            async getToken() {
                const { code, state } = this.getParams();

                if (!code || !state) {
                    return;
                }

                const { origin, pathname } = window.location;

                const clientId = process.env.VUE_APP_DE_CLIENT_ID;

                const statePass = this.checkState(state);
                localStorage.removeItem('careers-app-state');

                if (!statePass) {
                    window.history.replaceState({}, document.title, window.location.pathname);
                    window.location.reload();
                    return;
                }

                const reqParams = new URLSearchParams([
                    ['code', code],
                    ['client_id', clientId],
                    ['grant_type', 'authorization_code'],
                    ['redirect_uri', `${origin}${pathname}`],
                ]);

                try {
                    await this.requestToken(reqParams);
                } catch (e) {
                    this.error = e;
                } finally {
                    this.loading = false;
                    window.history.replaceState({}, document.title, window.location.pathname);
                    window.location.reload();
                }
            },
            async getTokenSilently() {
                const tokenKey = this.getTokenKey();
                const fromStorage = localStorage.getItem(tokenKey);
                if (!fromStorage && this.isAuthenticated) {
                    this.logout();
                    return null;
                }
                const { access_token: accessToken, expiry } = JSON.parse(fromStorage);

                let now = new Date();

                now = now.getTime();
                // if access token has expired, get a new one
                if (now > expiry) {
                    const refreshTokenKey = this.getTokenKey(true);
                    const { refresh_token: refreshToken } = JSON.parse(localStorage.getItem(refreshTokenKey));

                    return this.refreshToken(refreshToken);
                }

                return accessToken;
            },
            async getUser() {
                const tokenKey = this.getTokenKey();
                return localStorage.getItem(tokenKey);
            },
            async checkDEAuthentication() {
                const accessToken = await this.getUser();
                return !!accessToken;
            },
            checkState(stateRemote) {
                const stateLocal = localStorage.getItem('careers-app-state');
                return stateLocal === stateRemote;
            },
            login() {
                const state = window.crypto.randomUUID();
                const currentDomain = window.location.origin;
                localStorage.setItem('careers-app-state', state);

                const params = new URLSearchParams([
                    ['response_type', 'code'],
                    ['client_id', options.clientId],
                    ['state', state],
                    ['redirect_uri', `${currentDomain}/oauth2/authorized`],
                    ['scope',
                        'https://api.discoveryeducation.com/scopes/user.readonly https://api.discoveryeducation.com/scopes/asset.readonly',
                    ],
                ]).toString();

                window.location.href = `${options.deDomain}?${params}`;
            },
            logout() {
                const accessTokenKey = this.getTokenKey();
                const refreshTokenKey = this.getTokenKey(true);

                localStorage.removeItem(accessTokenKey);
                localStorage.removeItem(refreshTokenKey);

                this.isAuthenticated = false;
                window.location.reload();
            },
        },
        async created() {
            try {
                // If the user is returning to the app after authentication..
                if (
                    window.location.search.includes('code=')
                    && window.location.search.includes('state=')
                    && window.location.pathname.includes('/oauth2/authorized')
                ) {
                    this.getToken();
                }
            } catch (e) {
                this.error = e;
            }
            this.isAuthenticated = await this.checkDEAuthentication();
            this.loading = false;
        },
    });

    return instance;
};

const getInstance = () => instance;

/* eslint no-param-reassign: ["error", { "props": false }] */
const deAuthPlugin = {
    install(vue = Vue, options) {
        vue.prototype.$de = deAuth(options);
    },
};

export default { deAuthPlugin, getInstance };
